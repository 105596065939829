<template>
	<div>
		<TopTabBar :hidebelowtabs="true">
			<template v-slot:belowtabs>
			</template>
		</TopTabBar>
		<div class="body-hold hide-below-tabs">
			<div>
				<div v-if="store.selectionObs.markets.length === 0 || store.selectionObs.surveys.length === 0">
					<p>Please choose at least one market and survey first, or upload a schedule using the options below.</p>
				</div>
				<div style="margin: 10px 20px 20px;">
					<div style="display: flex; align-content: center; margin-top: 15px; margin-bottom: 5px;">
						<p class="subheading" style="margin-bottom: 0; display: flex; align-items: center;">
							Optimiser Options
						</p>
					</div>
				</div>

				<div class="view-holder" v-if="store.selectionObs.markets.length > 0 && store.selectionObs.surveys.length > 0">
					<div class="option-grouper">
						<span style="margin: 0 5px 0 0; font-weight: normal; font-size: 12px;">Scenario</span>
						<select v-model="optimiserSettings.scenario" class="selectbox">
							<option value="free">Promos or Free Spots (no rates/ratecard), limited by spot count per week</option>
							<option value="paid">Regular Spot Schedule (uses rates/ratecard), limited by budget</option>
						</select>
					</div>
					<div class="option-grouper">
						<span style="margin: 0 5px 0 0; font-weight: normal; font-size: 12px;">Granularity</span>
						<select v-model="optimiserSettings.granularity" class="selectbox" style="margin-right: 20px;">
							<option value="daypart">Daypart</option>
							<option value="hour">Hour</option>
							<option value="halfhour">Half Hour</option>
							<option value="qtrHr">Quarter Hour</option>
						</select>
						<span style="margin: 0 5px 0 0; font-weight: normal; font-size: 12px;">Optimise For</span>
						<select v-model="optimiserSettings.optimiseFor" class="selectbox" style="margin-right: 20px;">
							<option value="reach">Reach</option>
							<option value="impacts">Impacts</option>
						</select>
						<div style="display: inline-block;">
							<span style="margin: 0 5px 0 0; font-weight: normal; font-size: 12px;">Max Spots Per Station/Daypart per week</span>
							<input type="number" class="rfinputfield num" v-model="optimiserSettings.maxSpotsPerStationDaypartWeek" style="margin-right: 20px;" />
						</div>
					</div>
					<div class="option-grouper">
						<span style="margin: 0 5px 0 0; font-weight: normal; font-size: 12px;">Number of weeks</span>
						<input type="number" class="rfinputfield num" v-model="optimiserSettings.nWeeks" style="margin-right: 20px;" />
						<div v-if="optimiserSettings.scenario === 'paid'" style="display: inline-block;">
							<span style="margin: 0 5px 0 0; font-weight: normal; font-size: 12px;">Max Budget (for total campaign - will be split by week in calculation)</span>
							<input type="number" class="rfinputfield num" v-model="optimiserSettings.budgetMax" style="margin-right: 20px;" />
						</div>
						<div v-if="optimiserSettings.scenario === 'free'" style="display: inline-block;">
							<span style="margin: 0 5px 0 0; font-weight: normal; font-size: 12px;">Max Spots Per Week</span>
							<input type="number" class="rfinputfield num" v-model="optimiserSettings.maxSpotsPerWeek" style="margin-right: 20px;" />
						</div>
					</div>

					<div>
						<p class="subheading" style="margin: 20px 0 10px; display: flex; align-items: center;">
							Sessions To Use In Schedule
						</p>
						<div style="margin-bottom: 20px; font-size: 12px;">
							<span class="togglers-schedule" style="font-weight: normal;">
								<label><input type="checkbox" v-model="chosenAudioTypes[0]" /> Total Radio</label>
								<label><input type="checkbox" v-model="chosenAudioTypes[1]" /> AM/FM/DAB</label>
								<label><input type="checkbox" v-model="chosenAudioTypes[2]" /> Streaming</label>
							</span>
							<span style="margin-left: 100px;" @click="doDummyRates" class="cursor-pointer">Dummy Rates</span>
						</div>
						<div>
							<table class="std-tbl">
								<thead>
									<tr>
										<th @click="toggleActiveAll" class="cursor-pointer">Include</th>
										<th>Station</th>
										<th>Daypart</th>
										<th>Audio Type</th>
										<th v-if="showRates">Rate</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="session in sessions" :key="session.id">
										<td><input type="checkbox" v-model="session.active"></td>
										<td>{{getStationNameFromId(session.stationId)}}</td>
										<td>{{getDaypartNameFromId(session.daypartId)}}</td>
										<td>{{getAudioTypeNameFromId(session.audioTypeId)}}</td>
										<td v-if="showRates"><input type="number" class="rfinputfield num" v-model="session.rate" /></td>
									</tr>
								</tbody>
							</table>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import TopTabBar from "@/components/TopTabBar.vue";

export default {
	name: "OptimiserView",
	components: {
		TopTabBar,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		optimiserSettings: {
			get() {
				return this.store.optimiserSettings
			},
			set(value) {
				this.store.optimiserSettings = value
			}
		},
		reportType() {
			return this.store.reportType
		},
		showRates() {
			return this.store.optimiserSettings.scenario === 'paid'
		},
		sessions: {
			get() {
				return this.store.optimiserSessions
			},
			set(value) {
				this.store.optimiserSessions = value
			}
		},
		chosenAudioTypes: {
			get() {
				return this.store.optimiserSessionRadioTypes
			},
			set(value) {
				this.store.optimiserSessionRadioTypes = value
			}
		},
	},
	methods: {
		toggleActiveAll() {
			let firstSess = this.sessions[0]
			if(firstSess.active) {
				this.sessions.forEach(s => s.active = false)
			}
			else {
				this.sessions.forEach(s => s.active = true)
			}
		},
		doDummyRates() {
			this.sessions.forEach(s => {
				if(s.rate === 0) s.rate = 100
			})
		},
	},
	watch: {
		chosenAudioTypes: {
			handler() {
				this.generateOptimiserSessionsFromSelections()
			},
			deep: true
		},
	},
	mounted() {
		this.generateOptimiserSessionsFromSelections()
	}
}
</script>

<style scoped>
.option-grouper {
	margin: 0 0 10px 0;
}
/*
.view-holder {
	margin: 0 20px;
}
.input-dater {
	margin: 0 5px;
	font-weight: normal;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid var(--greymid);
}
*/
</style>
<template>
	<div v-if="store.user.id > 0">
		<MenuBar></MenuBar>

		<ReportTypeChooser v-if="store.reportType === null"></ReportTypeChooser>
		<MarketsView v-if="store.activeTab === 'Markets'"></MarketsView>
		<SurveysView v-if="store.activeTab === 'Surveys'"></SurveysView>
		<StationsView v-if="store.activeTab === 'Stations'"></StationsView>
		<DemographicsView v-if="store.activeTab === 'Demographics'"></DemographicsView>
		<DaypartsView v-if="store.activeTab === 'Dayparts'"></DaypartsView>
		<StatisticsView v-if="store.activeTab === 'Statistics'"></StatisticsView>
		<ScheduleView v-if="store.activeTab === 'Schedule'"></ScheduleView>
		<OptimiserView v-if="store.activeTab === 'Optimiser'"></OptimiserView>
		<ResultsView v-if="store.activeTab === 'Results'"></ResultsView>

		<RFTypePrompt v-if="store.rfTypePromptOverlayShown"></RFTypePrompt>
		<JobsManage v-if="store.jobManageOverlayShown"></JobsManage>
<!--		<BatchManage v-if="store.batchManageOverlayShown"></BatchManage>-->
		<CombosManage v-if="store.combosManageOverlayShown"></CombosManage>
		<ComboEdit v-if="store.comboEditOverlayShown"></ComboEdit>
		<DemosManage v-if="store.demosManageOverlayShown"></DemosManage>
		<DaypartsManage v-if="store.daypartManageOverlayShown"></DaypartsManage>
		<DemoEdit v-if="store.demoEditOverlayShown"></DemoEdit>
		<DaypartEdit v-if="store.daypartEditOverlayShown"></DaypartEdit>
		<FreqChartExplorer v-if="store.chartExplorerOverlayShown"></FreqChartExplorer>
		<PostcodeMapperOverlay v-if="store.postcodeMapperOverlayShown"></PostcodeMapperOverlay>

	</div>
</template>

<script>
import axios from "axios";
import MenuBar from "@/components/MenuBar";
import ReportTypeChooser from "@/components/ReportTypeChooser";
import JobsManage from "@/components/overlays/JobsManage";
// import BatchManage from "@/components/overlays/BatchManage";
import CombosManage from "@/components/overlays/CombosManage";
import DemosManage from "@/components/overlays/DemosManage";
import FreqChartExplorer from "@/components/views/FreqChartExplorer";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import DemoEdit from "@/components/overlays/DemoEdit";
import DaypartEdit from "@/components/overlays/DaypartEdit";
import DaypartsView from "@/components/views/DaypartsView";
import DemographicsView from "@/components/views/DemographicsView";
import MarketsView from "@/components/views/MarketsView";
import ResultsView from "@/components/views/ResultsView";
import ScheduleView from "@/components/views/ScheduleView";
import StationsView from "@/components/views/StationsView";
import SurveysView from "@/components/views/SurveysView";
import StatisticsView from "@/components/views/StatisticsView";
import ComboEdit from "@/components/overlays/ComboEdit";
import DaypartsManage from "@/components/overlays/DaypartsManage.vue";
import RFTypePrompt from "@/components/overlays/RFTypePrompt.vue";
import PostcodeMapperOverlay from "@/components/views/PostcodeMapperOverlay.vue";
import OptimiserView from "@/components/views/OptimiserView.vue";
export default {
	name: "HomePage",
	components: {
		OptimiserView,
		RFTypePrompt,
		DaypartsManage,
		ComboEdit,
		StatisticsView,
		SurveysView,
		StationsView,
		ScheduleView,
		ResultsView,
		MarketsView,
		DemographicsView,
		DaypartsView,
		DaypartEdit,
		DemoEdit,
		FreqChartExplorer,
		DemosManage,
		CombosManage,
		// BatchManage,
		JobsManage,
		ReportTypeChooser,
		// TopTabBar,
		MenuBar,
		PostcodeMapperOverlay,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetchingInitial: false,
		}
	},
	computed: {
		userid() {
			return this.store.user.id;
		},
		// apiroot() {
		// 	return this.store.apiroot;
		// },
	},
	methods: {
		getInitialObjects() {
			if(this.fetchingInitial === false) {
				this.fetchingInitial = true;
				let self = this;
				axios.post(
					"/post/get-initial-objects.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.markets) { //handle returned objects for lists
						self.setObjectLists(ret);
					}
					if (ret.error === 1) {
						self.form1working = false;
						self.showKalert(ret.errob)
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {
		userid() { //once we get a userid
			if(this.userid > 0) { //called after login/session confirmation
				this.getInitialObjects();
			}
		},
	},
	mounted() {
		document.title = "Audology";
		if(this.userid > 0) { //to catch anyone who has been automatically logged in
			this.getInitialObjects();
		}
	}
}
</script>

<style scoped>

</style>
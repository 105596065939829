<template>
	<div>
		<div style="font-size: 12px; text-transform: uppercase; padding: 5px 0; margin-top: 10px; font-weight: bold;" class="">
			<i class="mdi mdi-folder-outline"></i>
			{{folderob.name}}
			<span v-if="folderExpanded" @click="folderExpanded = false" style="cursor:pointer;"><i class="mdi mdi-minus"></i></span>
			<span v-else @click="folderExpanded = true"><i class="mdi mdi-plus" style="cursor:pointer;"></i></span>
			<span v-if="folderExpanded && filteredCombosInFolder.length > 0" @click="$emit('selectall', filteredCombosInFolder)" style="cursor:pointer; margin-left: 30px;" title="Select all in folder">
				<i class="mdi mdi-transfer-right"></i>
			</span>
		</div>
		<div v-for="stn in filteredCombosInFolder" :key="stn.id" class="checklist-item">
			<div v-if="folderExpanded" class="ch-and-title"
				@mousedown.left="$emit('togglecombo', stn)" @mouseover="selectionToggleDrag(stn)"
			>
				<span class="toggler">
					<i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i>
				</span>
				<span class="label" :id="'stnpop'+stn.id">{{stn.name}}</span>
				<div class="dp-edit-hold" v-if="stn.userid === store.user.id" @click="editCombo(stn.id)" title="Edit combo">
					<i class="mdi mdi-pencil-outline"></i>
				</div>
			</div>
			<InfoIcon v-if="folderExpanded" v-on:clicked="$emit('showpopover', stn)" addclasses="small" title="Click to view combo information"></InfoIcon>
		</div>
	</div>
</template>

<script>
import InfoIcon from "@/components/InfoIcon"
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ComboViewFolder",
	components: {
		InfoIcon
	},
	mixins: [globalMixin],
	props: {
		combos: Array,
		folderob: Object,
	},
	data: function () {
		return {
			store: store,
			showingFolderChooser: false,
			chosenFolderId: 0,
			activeComboId: 0,
			folderExpanded: true,
		}
	},
	computed: {
		filteredCombosInFolder() {
			return this.combos.filter(item => item.infolder === this.folderob.id);
		},
	},
	methods: {
		editCombo(cid) {
			this.store.editComboId = cid
			this.store.comboEditOverlayShown = true
		},
		selectionToggleDrag(elem) {
			if(window.mouseIsDown === 1) {
				this.$emit('togglecombo', elem)
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>
<template>
	<div style="width: 100%; max-width: 1200px;">
		<QueryErrorNote v-if="store.lookupQueryFailed === true"></QueryErrorNote>
		<div v-else>
			<div>
				<p style="line-height: 1.6"><span class="bold">Market: </span>{{mktname}}</p>
				<p style="line-height: 1.6"><span class="bold">Survey: </span>{{survname}}</p>
				<p style="line-height: 1.6"><span class="bold">Demographic: </span>{{demoname}}</p>
			</div>

			<div>
				<p class="subheading" style="margin: 20px 0 10px; display: flex; align-items: center;">
					Summary
				</p>
				<table class="std-tbl">
					<tbody>
						<tr>
							<td>Cost</td>
							<td>{{numdisplay(results.campaignCost)}}</td>
						</tr>
						<tr>
							<td>Spots</td>
							<td>{{numdisplay(results.campaignSpots)}}</td>
						</tr>
						<tr>
							<td>Cume / Reach</td>
							<td>{{numdisplay(results.campaignCume)}}</td>
						</tr>
						<tr>
							<td>Impacts</td>
							<td>{{numdisplay(results.campaignImpacts)}}</td>
						</tr>
						<tr>
							<td>CPM</td>
							<td>{{numdisplayNdp(results.campaignCPM,2)}}</td>
						</tr>
						<tr>
							<td>Avg Freq</td>
							<td>{{numdisplayNdp(results.campaignAvgFreq,1)}}</td>
						</tr>
						<tr>
							<td>Avg Aud (per spot)</td>
							<td>{{numdisplay(results.campaignAvgAudPerSpot)}}</td>
						</tr>
					</tbody>
				</table>

				<p class="subheading" style="margin: 20px 0 10px; display: flex; align-items: center;">
					Spend By Station
				</p>
				<table v-if="results.spendByStation" class="std-tbl">
					<thead>
						<tr>
							<th>Station</th>
							<th>Spots</th>
							<th>Cost</th>
							<th>Avg Rate</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="sp in results.spendByStation" :key="sp.stationId">
							<td>{{sp.stationName}}</td>
							<td>{{sp.nSpots}}</td>
							<td>{{numdisplay(sp.spend)}}</td>
							<td>{{numdisplay(sp.avgRate)}}</td>
						</tr>
					</tbody>
				</table>

				<p class="subheading" style="margin: 20px 0 10px; display: flex; align-items: center;">
					Spots By Station / Daypart
				</p>
				<table v-if="results.spotsByStation" class="std-tbl">
					<thead>
					<tr>
						<th>Station</th>
						<th>Daypart</th>
						<th>Radio Type</th>
						<th>Spots</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="sp in results.spotsByStation" :key="sp.stationId">
						<td>{{sp.stationName}}</td>
						<td>{{sp.daypartName}}</td>
						<td>{{getAudioTypeNameFromId(sp.audioTypeId)}}</td>
						<td>{{sp.nSpots}}</td>
					</tr>
					</tbody>
				</table>

				<p class="subheading" style="margin: 20px 0 10px; display: flex; align-items: center;">
					Spots List
				</p>
				<table v-if="spotList" class="std-tbl">
					<thead>
					<tr>
						<th>Spot</th>
						<th>Week</th>
						<th>Station</th>
						<th>Daypart</th>
						<th>Radio Type</th>
						<th>Avg Aud</th>
						<th>Cumulative Reach</th>
						<th>Incremental Reach</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="sp in spotList" :key="sp.spotNum">
						<td>{{sp.spotNum}}</td>
						<td>{{sp.weekNum}}</td>
						<td>{{sp.stationName}}</td>
						<td>{{sp.daypartName}}</td>
						<td>{{getAudioTypeNameFromId(sp.audioTypeId)}}</td>
						<td>{{numdisplay(sp.avgAud)}}</td>
						<td>{{numdisplay(sp.cumulativeReach)}}</td>
						<td>{{numdisplay(sp.incrementalReach)}}</td>
					</tr>
					</tbody>
				</table>
			</div>

		</div>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import QueryErrorNote from "@/components/QueryErrorNote.vue";

export default {
	name: "OptimiserReportPage",
	components: {
		QueryErrorNote,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,


		}
	},
	computed: {
		results() {
			if(this.store.returnedData.results) return this.store.returnedData.results
			return []
		},
		spotList() {
			if(this.store.returnedData.spotList) return this.store.returnedData.spotList
			return []
		},
		retinputs() {
			return this.store.returnedDataInputs
		},
		mktname() {
			if(this.retinputs.marketId) {
				let mkt = this.store.markets.find(m => m.id === this.retinputs.marketId)
				if(mkt) return mkt.name
			}
			return ''
		},
		survname() {
			if(this.retinputs.survcode) {
				let surv = this.store.surveys.find(s => s.id === this.retinputs.survcode)
				if(surv) return surv.name
			}
			return ''
		},
		demoname() {
			if(this.retinputs.demoId) {
				let demo = this.store.demographics.find(d => d.id === this.retinputs.demoId)
				if(demo) return demo.name
			}
			return ''
		},
	},
	methods: {

	},
	watch: {
		'store.returnedData': {
			handler() {

			}
		},
	},
	mounted() {

	}
}
</script>

<style scoped>

</style>

import axios from "axios";

export const globalMixin = {
	methods: {
		numdisplay: function (num) {
			if(num == 0) return 0
			if(!num) return null
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		numdisplayNdp: function (num, numberOfDecplc) {
			if(num == 0) return 0
			if(!num) return null
			return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
		},
		flround(num, nDecimals = 0) {
			if(num == 0) return 0
			if(!num) return null
			if(isNaN(num)) {
				num = parseFloat(num.replace(/,/g, ''))
			}
			num = parseFloat(num)
			try {
				return parseFloat(num.toFixed(nDecimals))
			} catch (e) {
				return num
			}
		},
		numdisplayKeepExisting: function(num, nDecimals = null) {
			if(!num && num !== 0) return null
			if(this.store.viewPreferences.commasInData === true) {
				if(nDecimals !== null) {
					return num.toLocaleString('en-US', {minimumFractionDigits: nDecimals, maximumFractionDigits: nDecimals});
				}
				return num.toLocaleString('en-US');
			}
			else if(num && !isNaN(num) && nDecimals !== null) { //no commas but need decimals
				return num.toFixed(nDecimals)
			}
			return num
		},
		numOrNull(val) {
			if(!val || val && val.length === 0) val = 0
			val = parseFloat(val)
			if(val === 0) return null
			return val
		},
		numOrZero(val) {
			if(!val || val && val.length === 0) val = 0
			val = parseFloat(val)
			if(val === 0) return 0
			return val
		},
		ucfirst: function (str) {
			if(!str) return ''
			if(str.length === 0) return str;
			return str[0].toUpperCase() + str.substring(1);
		},
		intToYes: function (str) {
			if (str) {
				if (parseInt(str) === 1) return "Yes";
			}
			return '';
		},
		leftPadNum(int) {
			if(int < 10) return '0'+int
			return int
		},
		boolToYes(bool) {
			if (bool && bool === true) return "Yes";
			return '';
		},
		zeroToBlank(str) {
			if (parseInt(str) === 0) {
				return '';
			}
			return str;
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			let a = new Date(unix_timestamp * 1000);
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			let year = a.getFullYear();
			let month = months[a.getMonth()];
			let date = a.getDate();

			let timestr = "";
			if (includeTime === true) {
				let hour = a.getHours();
				let min = a.getMinutes();
				if (min < 10) min = "0" + min;
				// let sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		niceMonthFromInt(int) {
			let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
			int = parseInt(int)
			return months[int - 1];
		},
		niceDayFromInt(int) {
			let days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
			int = parseInt(int)
			return days[int]
		},
		ntobr(str) {
			return str.replaceAll("\n", "<br/>");
		},
		areArraysIdentical(arrA, arrB) {
			if (arrA === arrB) return true;
			if (arrA == null || arrB == null) return false;
			if (arrA.length !== arrB.length) return false;
			arrA.sort();
			arrB.sort();
			// If you don't care about the order of the elements inside the array, you should sort both arrays here.
			// Please note that calling sort on an array will modify that array. You might want to clone your array first.
			for (let i = 0; i < arrA.length; ++i) {
				if (arrA[i] !== arrB[i]) return false;
			}
			return true;
		},
		stripTagsFromString(str) {
			return str.replace(/(<([^>]+)>)/gi, "");
		},
		clearLocalStorage() {
			window.localStorage.removeItem('useremail');
			window.localStorage.removeItem('usertoken');
			window.localStorage.removeItem('userid');
		},
		joinObjectIds(obs, keyname = 'id', sep = '|') {
			let arr = this.getObIdsToArr(obs, keyname)
			return arr.join(sep)
		},
		getObIdsToArr(obs, keyname = 'id') {
			let arr = []
			for(let ob of obs) {
				arr.push(ob[keyname])
			}
			return arr //an array of integers/strings
		},
		getObjectsFromArrayById(inobarr, idarr) {
			let arr = []
			for(let id of idarr) {
				let newob = inobarr.find(item => item.id === id)
				if(newob) arr.push(newob)
			}
			return arr
		},
		prepareSurveySetForSend(savingToJob = false) { //get surveys into array format we require for request & job save
			let surveyArr = []
			let hasGFK = false
			let hasXtra = false
			for(let mysg of this.store.selectionObs.surveys) {
				let survids = []
				for(let s of mysg.survobs) {
					//handle relative metro survey equiv
					if(s.id.indexOf('GF2') === 0) { //eg GF202203
						let scode = s.id.replace('GF','')
						for(let m of this.store.selectionObs.markets) {
							if(m.shortcode.length === 2) hasGFK = true
							else hasXtra = true
							survids.push(m.shortcode + '_eqsrv' + scode) //ME_eqsrv2022s2
						}
					}
					else if(s.id.indexOf('latest') === 0) { //relative latest options
						for(let m of this.store.selectionObs.markets) {
							if(m.shortcode.length === 2) hasGFK = true
							else hasXtra = true
							if(savingToJob === true) { //don't save the shortcode at front
								if(!survids.includes(s.id)) survids.push(s.id)
							}
							else survids.push(m.shortcode + '_' + s.id) //ME_latest-2 - normal API submit
						}
					}
					else { //normal survey
						if (s.isGfk === true) hasGFK = true
						else hasXtra = true
						survids.push(s.id)
					}
				}
				surveyArr.push({id: mysg.id, name: mysg.name, survids: survids})
			}
			if(hasGFK && hasXtra) this.store.viewPreferences.cumePrecisionLevel = 1
			else if(hasGFK && !hasXtra) this.store.viewPreferences.cumePrecisionLevel = 1000
			else if(!hasGFK && hasXtra) this.store.viewPreferences.cumePrecisionLevel = 100
			return surveyArr
		},
		prepareStationsAndCombosForSend() {
			let stnarr = []
			let stnInd = 0
			for(let ob of this.store.selectionObs.stations) {
				if(ob.isCombo && ob.isCombo === true) {
					stnarr.push({id: ob.id, stnIdArr: ob.stationIDsArr, isCombo: true, ind: stnInd})
				}
				else {
					stnarr.push({id: ob.id, stnIdArr: [ob.id], isCombo: false, ind: stnInd})
				}
				stnInd++
			}
			return stnarr
		},
		handleIncomingSurveysetsFromJob(incomingSets) {
			let surveySets = []
			let eqsurvids = []
			for(let set of incomingSets) {
				let isStoredSurveyCombo = false
				if(set.id && set.id.indexOf('sc_') > -1) { //this is a saved surveyset
					let scitem = this.store.surveycombos.find(item => item.id === set.id)
					if(scitem) {
						surveySets.push(scitem)
						isStoredSurveyCombo = true
					}
				}
				if(isStoredSurveyCombo === false) { //we don't have a mapped saved one, use explicit from job
					let mysurveyobs = [];
					for (let sid of set.survids) {
						if (this.store.surveys.find(item => item.id === sid)) {
							mysurveyobs.push(this.store.surveys.find(item => item.id === sid))
						}
						else if (this.store.relativeLatestSurveyObs.find(item => item.id === sid)) {
							mysurveyobs.push(this.store.relativeLatestSurveyObs.find(item => item.id === sid))
						}
						else if (sid.includes('eqsrv')) {
							//incoming is something like SY_eqsrv202302, need to convert to GF202302
							let tempsid = sid.split('_')[1]
							tempsid = 'GF' + tempsid.replace('eqsrv', '')
							if (eqsurvids.includes(tempsid) === false) {
								mysurveyobs.push(this.store.metroEquivalentSurveyObs.find(item => item.id === tempsid))
								eqsurvids.push(tempsid)
							}
						}
					}
					let isFolder = false
					if (mysurveyobs.length > 1) isFolder = true
					surveySets.push({
						id: set.id,
						name: set.name,
						survobs: mysurveyobs,
						isFolder: isFolder,
					})
				}
			}
			return surveySets
		},
		splitpipe(valstr) {
			let ret = [];
			if (valstr !== "") {
				if (isNaN(valstr)) {
					if (valstr.indexOf("|") > 0) {
						let temp = valstr.split("|");
						for (let i = 0; i < temp.length; i++) {
							let ti = parseInt(temp[i]);
							ret.push(ti);
						}
					}
					else {
						ret.push(parseInt(valstr));
					}
				}
				else {
					ret.push(valstr);
				}
			}
			return ret;
		},
		cleanMSWord(text) {
			let s = text;
			s = s.replace(/[\u2018|\u2019|\u201A]/g, "'"); // smart single quotes and apostrophe
			s = s.replace(/[\u201C|\u201D|\u201E]/g, "\""); // smart double quotes
			s = s.replace(/\u2026/g, "..."); // ellipsis
			s = s.replace(/[\u2013|\u2014]/g, "-"); // dashes
			s = s.replace(/\u02C6/g, "^"); // circumflex
			s = s.replace(/\u2039/g, ""); // open angle bracket
			s = s.replace(/[\u02DC|\u00A0]/g, " "); // spaces
			return s;
		},
		convertNetworkString(str) {
			if(str === 'arn') return 'ARN';
			else if(str === 'sca') return 'SCA';
			else if(str === 'ace') return 'Ace Radio';
			else if(str === 'abc') return 'ABC';
			else if(str === 'nova') return 'Nova Entertainment';
			else if(str === 'super') return 'Super Radio Network';
			else if(str === 'eon') return 'EON Broadcasters';
			else if(str === 'resonate') return 'Resonate';
			else if(str === 'capital') return 'Capital Radio';
			else if(str === 'gb') return 'Grant Broadcasters';
			else if(str === 'wcr') return 'West Coast Radio';
			else if(str === 'win') return 'WIN Radio';

			else if(str === 'arn_k') return 'KIIS Network';
			else if(str === 'arn_p') return 'Pure Gold Network';
			else if(str === 'sca_m') return 'Triple M Network';
			else if(str === 'sca_h') return 'Hit Network';
			else if(str === 'abc_loc') return 'Local Radio';
			else if(str === 'abc_rn') return 'Radio National';
			else if(str === 'abc_news') return 'News Radio';
			else if(str === 'abc_j') return 'Triple J';
			else if(str === 'abc_cl') return 'Classic FM';
			else if(str === 'ace_am') return 'Heritage Network';
			else if(str === 'ace_fm') return 'FM Network';

			return '';
		},
		toggleStationInSelection(ob) {
			let temparr = this.store.selectionObs.stations;
			if(temparr.some(elem => elem.id === ob.id) && window.mouseIsDown === 0) {
				temparr = temparr.filter(item => item.id !== ob.id); //remove it
			}
			else if(!temparr.some(elem => elem.id === ob.id)) temparr.push(ob); //add it at end of array
			this.store.selectionObs.stations = temparr;
		},
		addMultipleStationsToSelection(obarr) {
			for(let ob of obarr) {
				this.addStationToSelection(ob)
			}
		},
		addStationToSelection(ob) {
			let temparr = this.store.selectionObs.stations;
			if(!temparr.some(elem => elem.id === ob.id)) temparr.push(ob); //add it at end of array
			this.store.selectionObs.stations = temparr;
		},
		toggleMarketInSelection(ob) {
			let temparr = this.store.selectionObs.markets;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else temparr.push(ob); //add it at end of array
			this.store.selectionObs.markets = temparr;
		},
		toggleDemoInSelection(ob) {
			let temparr = this.store.selectionObs.demographics;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else temparr.push(ob); //add it at end of array
			this.store.selectionObs.demographics = temparr;
		},
		generateCampaignLinesFromSelections() {
			let lines = []
			let hasGFKMetroMarkets = false
			for(let m of this.store.selectionObs.markets) {
				if(this.store.gfkMetroMktIds.includes(m.id)) {
					hasGFKMetroMarkets = true
					break
				}
			}
			for(let stn of this.store.selectionObs.stations) {
				let atypes = []
				for(let dp of this.store.selectionObs.dayparts) {
					let stnid = stn.id
					let dpid = dp.id
					// if(this.store.viewPreferences.rfShowTotalRadioRows === true) {
						if(!atypes.includes(0)) atypes.push(0)
					// }
					if(this.store.gfkMetroMktIds.includes(stn.mktid) || (hasGFKMetroMarkets === true && stn.id.indexOf('c_') === 0)) {
						// if(this.store.viewPreferences.rfShowAMFMDABRows === true) {
							if(!atypes.includes(1)) atypes.push(1)
						// }
						// if(this.store.viewPreferences.rfShowStreamingRows === true) {
							if(!atypes.includes(2)) atypes.push(2)
						// }
					}
					for(let atype of atypes) {
						let existrow = this.store.rfCampaignLinesAll.find(item => item.stationId === stnid && item.daypart === dpid && item.audioType === atype)
						if (existrow) {
							lines.push(existrow)
						}
						else {
							let newrow = this.newBlankCampaignLine(stnid, dpid, atype)
							lines.push(newrow)
						}
					}
				}
			}
			this.store.rfCampaignLinesAll = lines
		},
		newBlankCampaignLine(stnid, dpid, atype) {
			let ob = {
				stationId: stnid,
				daypart: dpid,
				audioType: atype,
				spotRate: null,
				ratecardRate: null,
				spotClass: null,
			}
			if(this.periodSplit === 'day') ob.spotsByWeek = JSON.parse(JSON.stringify(this.store.defaultRfCampaignDays))
			else ob.spotsByWeek = JSON.parse(JSON.stringify(this.store.defaultRfCampaignWeeks))
			return ob
		},
		createDemoTempFromBase(baseTargetDemoOb, demoOb) {
			let ob = {
				id: baseTargetDemoOb.id + '_' + demoOb.id,
				name: baseTargetDemoOb.name + ' --> ' + demoOb.name,
				isAttribute: false,
				isCombinedDemo: true,
				type: 'demographic',
				xtra: false,
				gfk: true,
				isSystem: false,
				survey_code: null,
				userid: 0,
				siteAccessId: 0,
				settings: [],
				infolder: 0,
			}
			return ob
		},
		toggleDaypartInSelection(ob) {
			let temparr = this.store.selectionObs.dayparts;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else {
				if(!['ebbAndFlow','duplication'].includes(this.store.reportType)) {
					temparr.push(ob)
				}
				else if(this.store.reportType === 'ebbAndFlow' && temparr.length < 2) temparr.push(ob)
				else if(this.store.reportType === 'duplication' && temparr.length < 1) temparr.push(ob)

			} //add it at end of array
			this.store.selectionObs.dayparts = temparr;
		},
		generateOptimiserSessionsFromSelections() {
			let newsessions = []
			for(let st of this.store.selectionObs.stations) {
				let ats = [0]
				if(this.store.gfkMetroMktIds.includes(st.mktid)) {
					ats = [0,1,2]
				}
				let newats = []
				for(let at of ats) {
					if(this.store.optimiserSessionRadioTypes[at] === true) newats.push(at)
				}
				for(let dp of this.store.selectionObs.dayparts) {
					for(let at of newats) {
						let rate = 0
						let sessid = st.id+'|'+dp.id+'|'+at
						if(this.store.optimiserSessions.find(item => item.id === sessid)) {
							let existrow = this.store.optimiserSessions.find(item => item.id === sessid)
							newsessions.push(existrow)
						}
						else { //not already a row for this combination
							newsessions.push({
								active: true,
								id: sessid,
								daypartId: dp.id,
								stationId: st.id,
								audioTypeId: at,
								rate: rate,
							})
						}
					}
				}
			}
			this.store.optimiserSessions = newsessions
		},
		toggleStatisticInSelection(ob) {
			let temparr = this.store.selectionObs.statistics;
			if(temparr.some(elem => elem.id === ob.id)) temparr = temparr.filter(item => item.id !== ob.id); //remove it
			else temparr.push(ob); //add it at end of array
			this.store.selectionObs.statistics = temparr;
		},
		getResults() {
			this.store.calcIsRunning = true;
			this.store.lookupQueryFailed = false
			this.store.lookupQueryFailedMessage = ''
			let needsStations = ['normal','reachFrequency','optimiserReport'];
			let needsDemos = ['normal','reachFrequency','loyalty','ebbAndFlow','duplication','stationP1','multiRank','optimiserReport'];
			let needsDayparts = ['normal','multiRank','optimiserReport']
			let needsStatistics = ['normal','multiRank']
			let targ = "";
			let conf = {}
			if(this.store.reportType === 'normal') targ = "/api/insights"
			else if(this.store.reportType === 'reachFrequency') targ = "/api/reach-and-frequency"
			else if(this.store.reportType === 'tslCrosstabs') targ = "/api/reg-tsl-crosstabs"
			else if(this.store.reportType === 'pctStnCumeByDaypart') targ = "/api/reg-pct-cume-by-daypart"
			else if(this.store.reportType === 'audSharingCumeReg') {
				targ = "/api/reg-aud-sharing"
				conf.type = 'cume'
			}
			else if(this.store.reportType === 'audSharingMostReg') {
				targ = "/api/reg-aud-sharing"
				conf.type = 'stnmost'
			}
			else if(this.store.reportType === 'regFlowFromBreakfast') targ = "/api/reg-flow-from-breakfast"
			else if(this.store.reportType === 'loyalty') targ = "/api/metro-loyalty"
			else if(this.store.reportType === 'ebbAndFlow') targ = "/api/metro-ebb-and-flow"
			else if(this.store.reportType === 'duplication') targ = "/api/metro-duplication"
			else if(this.store.reportType === 'stationP1') targ = "/api/station-p1"
			else if(this.store.reportType === 'multiRank') targ = "/api/multi-station-rank"
			else if(this.store.reportType === 'duplicationVenn') targ = "/api/duplication-venn"
			else if(this.store.reportType === 'optimiserReport') targ = "/api/optimiser"

			if(targ !== "") {
				if(this.store.selectionObs.surveys.length === 0) {
					this.showTempDangerAlert('Please choose at least one survey');
					this.setActiveTab('Surveys');
					return false;
				}
				if(this.store.selectionObs.stations.length === 0 && needsStations.includes(this.store.reportType)) {
					this.showTempDangerAlert('Please choose at least one station');
					this.setActiveTab('Stations');
					return false;
				}
				if(this.store.selectionObs.demographics.length === 0 && needsDemos.includes(this.store.reportType)) {
					this.showTempDangerAlert('Please choose at least one demographic');
					this.setActiveTab('Demographics');
					return false;
				}
				if(this.store.selectionObs.dayparts.length === 0 && needsDayparts.includes(this.store.reportType)) {
					this.showTempDangerAlert('Please choose at least one daypart');
					this.setActiveTab('Dayparts');
					return false;
				}
				if(this.store.selectionObs.statistics.length === 0 && needsStatistics.includes(this.store.reportType)) {
					this.showTempDangerAlert('Please choose at least one statistic');
					this.setActiveTab('Statistics');
					return false;
				}
				if(this.store.rfTotalSpots <= 0 && this.store.reportType === 'reachFrequency') {
					if(this.store.rfConfig.campaignLines && this.store.rfConfig.campaignLines.length > 0) {
						return false
					}
					this.showTempDangerAlert('Please add some spots to your schedule');
					this.setActiveTab('Schedule');
					return false;
				}
				this.store.showLoader = true;
				this.store.returnedData = []
				this.store.returnedDataSourcing = ''
				let rfjson = {};
				if(this.store.reportType === 'reachFrequency') {
					rfjson = this.store.rfConfig
				}
				let self = this;
				//get surveys into array format we require for request
				let surveyArr = this.prepareSurveySetForSend()
				//handle stations & combos
				let stnarr = this.prepareStationsAndCombosForSend()
				let contentSendJson= {
					vue: true,
					usercreds: self.store.usercreds,
					surveysets: surveyArr,
					stations: stnarr,
					demographics: self.getObIdsToArr(self.store.selectionObs.demographics),
					dayparts: self.getObIdsToArr(self.store.selectionObs.dayparts),
					statistics: self.getObIdsToArr(self.store.selectionObs.statistics),
					rfjson: rfjson,
					splitQtrHr: self.store.splitByQuarterHours,
					qtrHrSplitType: self.store.qtrHrSplitType,
					splitPOL: self.store.splitByPlaceOfListen,
					splitDOW: self.store.splitByDayOfWeek,
					splitAudType: self.store.splitByAudioType,
					showPrivateStations: self.store.showPrivateStations,
					conf: conf,
					jobId: self.store.jobid,
					shareableLinkSlug: self.store.shareableLinkSlug,
					numberStationsInMultiRank: self.store.numberStationsInMultiStationRank,
					multiStationRankCommercialOnly: self.store.multiStationRankCommercialOnly,
				}

				if(this.store.reportType === 'duplicationVenn') {
					let sgrp = this.store.selectionObs.surveys[0] //.find(item => item.id === this.store.dupeVennSurveyId)
					let survids = []
					for(let s of sgrp.survobs) {
						//handle relative metro survey equiv
						if(s.id.indexOf('GF2') === 0) { //eg GF202203
							let scode = s.id.replace('GF','')
							for(let m of this.store.selectionObs.markets) {
								survids.push(m.shortcode + '_eqsrv' + scode) //ME_eqsrv2022s2
							}
						}
						else if(s.id.indexOf('latest') === 0) { //relative latest options
							for(let m of this.store.selectionObs.markets) {
								survids.push(m.shortcode + '_' + s.id) //ME_latest-2 - normal API submit
							}
						}
						else { //normal survey
							survids.push(s.id)
						}
					}
					contentSendJson.surveysets = [{id: sgrp.id, name: sgrp.name, survids: survids}]
					contentSendJson.media1 = this.store.dupeVenn1
					contentSendJson.media2 = this.store.dupeVenn2
					contentSendJson.media3 = this.store.dupeVenn3
				}
				else if(this.store.reportType === 'optimiserReport') {
					contentSendJson.optimiserSessions = this.store.optimiserSessions
					contentSendJson.optimiserSettings = this.store.optimiserSettings
				}
				contentSendJson = JSON.stringify(contentSendJson)

				axios.post(targ, contentSendJson).then(function (response) {
					let ret = response.data; //response.data is returned info
					if(ret.sourcing) {
						self.store.returnedDataSourcing = ret.sourcing
					}
					if(ret.data) { //new normal report type
						self.store.returnedData = ret.data
						self.store.returnedDataInputs = ret.inputs
						if(ret.warnings) self.store.returnedWarnings = ret.warnings
					}
					else if (ret.vuetables) { //special report type, basic table layout created
						self.store.returnedData = ret.vuetables;
					}
					else if(ret.error) {
						self.showTempDangerAlert(ret.error);
					}
					else { //either blank or error from PHP fatal error/bug
						self.logFailedQuery(contentSendJson, targ)
						self.store.lookupQueryFailed = true
						self.store.lookupQueryFailedMessage = ret
					}
					if(ret.shareableLinkSlug) self.store.shareableLinkSlug = ret.shareableLinkSlug
					self.store.showLoader = false;
					self.store.selectionsHaveChangedNotYetRun = false;
					self.store.resultTableShownIndex = 0; //reset to view first table
					if(self.store.activeTab !== 'Results') {
						self.store.activeTab = 'Results'
					}
					self.refreshColourHighlights()
					setTimeout(function() {
						self.store.calcIsRunning = false
					}.bind(self), 200);
				}).catch(function (error) {
					console.log(error)
					self.store.showLoader = false
				});
			}
		},
		logFailedQuery(contentSendJson, targ) {
			contentSendJson = JSON.parse(contentSendJson)
			contentSendJson.targ = targ
			contentSendJson = JSON.stringify(contentSendJson)
			axios.post("/api/failed-query-error-log", contentSendJson)
		},
		refreshColourHighlights() {
			setTimeout(function () {
				if (this.store.showGreenHighlights === 'column') {
					this.highlightByCol()
				}
				else if (this.store.showGreenHighlights === 'row') {
					this.highlightByRow()
				}
			}.bind(this), 200);
		},
		newJob() {
			this.store.jobname = '';
			this.store.jobid = 0;
			this.clearAllSelections(false);
		},
		clearAllSelections(goToMarkets = true) {
			this.store.selectionObs = {
				markets: [],
				surveys: [],
				stations: [],
				demographics: [],
				dayparts: [],
				statistics: [],
			};
			this.store.splitByQuarterHours = false
			this.store.qtrHrSplitType = 'none'
			this.store.splitByPlaceOfListen = false
			this.store.splitByAudioType = false
			this.store.splitByDayOfWeek = false
			if(goToMarkets) this.store.activeTab = 'Markets';
			this.store.rfConfig = this.getDefaultRFConfig()
			this.store.rfConfig.schedule = []
			this.store.precampaignWeeksShown = 4
			this.store.tableLayout = this.getDefaultTableLayout()
			this.store.shareableLinkSlug = ''
		},
		getDefaultTableLayout() {
			return {
				tables: [
					{id: 'surveys', name: 'Survey'},
					{id: 'markets', name: 'Market'},
					{id: 'demographics', name: 'Demographics'},
					{id: 'dayparts', name: 'Dayparts'}
				],
					columns: [{id: 'statistics', name: 'Statistics'}],
					rows: [{id: 'stations', name: 'Stations'}],
					unused: [
					{id: 'qtrHrs', name: 'Quarter Hour'},
					{id: 'places', name: 'Place Of Listening'},
					{id: 'audioTypes', name: 'Radio Type'},
					{id: 'dows', name: 'Day Of Week'},
				]
			}
		},
		getDefaultRFConfig() {
			return {
				type: 'precampaign', //dayparts_standard, qtrhr, post_times, dayparts_full, goldstdcsv
				schedule: [],
				ratecard: {},
				ratecard_id: null,
				campaignLines: [],
				randomiseSpotTimesWithinCombos: false,
				precampaignLegacyView: false,
				precampaignWeekStartsOn: 'sun',
				precampaignFirstWeekDate: null,
			}
		},
		getDefaultViewPreferences() {
			return {
				cumePrecisionLevel: 1, //1, 100, 1000
				commasInData: true,
				showGroupedMarket: true,
				showStationNetworks: false,

				rfShowTotalRadioRows: true,
				rfShowAMFMDABRows: true,
				rfShowStreamingRows: true,
				rfPreSchedPeriod: 'week', //week, day - for precampaign grid split

				rfSummaryTableRowGroups: [
					{name: 'Total', enabled: false},
					{name: 'Markets', enabled: true},
					{name: 'Networks', enabled: true},
					{name: 'Networks x Market', enabled: false},
					{name: 'Stations', enabled: true},
					{name: 'Week (Cume)', enabled: true},
					{name: 'Week Splits', enabled: true},
				],
				rfSummaryTableColumns: [
					{name: 'Spot Count', enabled: true},
					{name: 'Sample Size', enabled: true},
					{name: 'Universe Est', enabled: true},
					{name: '1+ Reach', enabled: true},
					{name: 'Avg Frequency', enabled: true},
					{name: '1+ %', enabled: true},
					{name: '2+', enabled: false},
					{name: '2+ %', enabled: false},
					{name: '3+', enabled: true},
					{name: '3+ %', enabled: true},
					{name: 'Gross Impacts', enabled: true},
					{name: 'Avg Aud', enabled: true},
					{name: 'Cost', enabled: true},
					{name: 'CPM', enabled: true},
				],
			}
		},
		getDefaultOptimiserSettings() {
			return {
				scenario: 'free', //free - no cost eg promos, paid - uses costs
				nWeeks: 1,
				maxSpotsPerWeek: 10, //0 = no limit - used for Free scenario
				maxSpotsPerStationDaypartWeek: 0, //0 = no limit - used for Paid scenario
				granularity: 'daypart', //qtrHr, halfhour, hour, daypart (default)
				optimiseFor: 'reach', //reach, impacts
				budgetMax: 5000, //0 = no limit - used for total campaign, will be split evenly across weeks
			}
		},
		getDefaultOptimiserSessionRadioTypes() {
			return {
				0: true,
				1: false,
				2: false,
			}
		},
		prepareViewPrefsFromJob(jobviewprefs) {
			if(jobviewprefs) {
				let myprefs = {}
				let vp = this.getDefaultViewPreferences()
				for(let prop in vp) {
					if(!['rfSummaryTableRowGroups','rfSummaryTableColumns'].includes(prop)) {
						if (Object.prototype.hasOwnProperty.call(jobviewprefs, prop)) {
							myprefs[prop] = jobviewprefs[prop]
						}
						else {
							myprefs[prop] = vp[prop]
						}
					}
				}
				//make sure we have all the rfsummary table options
				if(!jobviewprefs.rfSummaryTableRowGroups) myprefs.rfSummaryTableRowGroups = vp.rfSummaryTableRowGroups
				else {
					let mygroups = []
					for(let setting of vp.rfSummaryTableRowGroups) {
						let thisgroup = jobviewprefs.rfSummaryTableRowGroups.find(item => item.name === setting.name)
						if(thisgroup) mygroups.push(thisgroup)
						else mygroups.push(setting)
					}
					myprefs.rfSummaryTableRowGroups = mygroups
				}

				if(!jobviewprefs.rfSummaryTableColumns) myprefs.rfSummaryTableColumns = vp.rfSummaryTableColumns
				else {
					let mygroups = []
					for(let setting of vp.rfSummaryTableColumns) {
						let thisgroup = jobviewprefs.rfSummaryTableColumns.find(item => item.name === setting.name)
						if(thisgroup) mygroups.push(thisgroup)
						else mygroups.push(setting)
					}
					myprefs.rfSummaryTableColumns = mygroups
				}

				this.store.viewPreferences = myprefs
			}
			else this.store.viewPreferences = this.getDefaultViewPreferences()
		},
		getTVAggMktName(aggmktid) {
			let m = this.store.tvaggmkts.find(item => item.id === aggmktid);
			if(m) return m.name;
			return '';
		},
		convertYMDtoDMY(ymd) {
			let arr = ymd.split('-')
			return arr[2] + '/' + arr[1] + '/' + arr[0];
		},
		highlightByCol() {
			this.clearHighlights();
			let mytbls = document.querySelectorAll(".std-tbl"), i;
			for(i=0; i<mytbls.length; i++) {
				let mytbl = mytbls[i];
				let myrows = mytbl.querySelectorAll("tbody tr:not(.norank)");
				if(myrows[0]) {
					let tdCount = myrows[0].querySelectorAll("td:not(.norank)").length;
					for (let c = 1; c < tdCount; c++) { //go through each col
						let vals = [];
						for (let r = 0; r < myrows.length; r++) { //looping through the rows within the col
							let myrow = myrows[r];
							let mycols = myrow.querySelectorAll("td:not(.norank)");
							let mycol = mycols[c];
							if(mycol) {
								let thisVal = mycol.innerText.replace(/,/g,'')
								if(!mycol.classList.contains('text')) vals.push(parseFloat(thisVal));
							}
						}
						let sortedIndex = vals.sort(function (a, b) {
							return b - a;
						});
						let cls = ["high", "high2", "high3", "high4"];
						for (let p = 0; p < 4; p++) {
							let tval = sortedIndex[p]; //nth value
							let thiscl = cls[p];
							for (let rr = 0; rr < myrows.length; rr++) {
								let thisrow = myrows[rr];
								if (thisrow.querySelectorAll("td")[c].innerText.replace(/,/g,'') == tval) {
									thisrow.querySelectorAll("td")[c].classList.add(thiscl);
								}
							}
						}
					}
				}
			}
			this.store.showGreenHighlights = 'column'
		},
		highlightByRow() {
			this.clearHighlights()
			let mytbls = document.querySelectorAll(".std-tbl"), i;
			for(i=0; i<mytbls.length; i++) {
				let mytbl = mytbls[i];
				let myrows = mytbl.querySelectorAll("tbody tr");
				for (let r = 0; r < myrows.length; r++) { //looping through the rows within the col
					let vals = [];
					let myrow = myrows[r];
					let mycols = myrow.querySelectorAll("td");
					for (let c = 1; c < mycols.length; c++) { //go through each col
						let mycol = mycols[c];
						let thisVal = mycol.innerText.replace(/,/g,'')
						if(!mycol.classList.contains('text')) vals.push(parseFloat(thisVal));
					}
					let sortedIndex = vals.sort(function (a, b) {
						return b - a;
					});
					let cls = ["high", "high2", "high3", "high4"];
					for (let p = 0; p < 4; p++) {
						let tval = sortedIndex[p]; //nth value
						let thiscl = cls[p];
						for (let cc = 0; cc < mycols.length; cc++) {
							if (mycols[cc].innerText.replace(/,/g,'') == tval) {
								mycols[cc].classList.add(thiscl);
							}
						}
					}
				}
			}
			this.store.showGreenHighlights = 'row'
		},
		clearHighlights() {
			let j = document.querySelectorAll(".std-tbl td");
			j.forEach(item => {item.classList.remove('high')});
			j.forEach(item => {item.classList.remove('high2')});
			j.forEach(item => {item.classList.remove('high3')});
			j.forEach(item => {item.classList.remove('high4')});
			this.store.showGreenHighlights = null
		},

		//tables stuff
		tblPrepareFilteredRows(rows, filterText = '', sortColName = null, sortColOrder = 'desc', forceKeepIds = []) {
			if(filterText.length > 0) { //add any filter by text input here
				let newer = [];
				let sboxval = filterText.toLowerCase();
				for (let r of rows) {
					for (let fld in r) {
						if(typeof(r[fld]) === 'string' && (isNaN(r[fld]) || parseFloat(r[fld]) != r[fld])) {
							if (forceKeepIds.includes(r.id) || r[fld].toLowerCase().indexOf(sboxval) > -1) {
								newer.push(r);
								break;
							}
						}
					}
				}
				rows = newer;
			}
			if(sortColName) {
				let useStrings = false;
				if(isNaN(rows[0][sortColName]) || parseFloat(rows[0][sortColName]) != rows[0][sortColName]) useStrings = true;
				if(sortColOrder === 'asc') {
					if(useStrings === true) {
						rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? 1 : -1))
					}
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? 1 : -1)
				}
				else {
					if(useStrings === true) rows = rows.sort((a, b) => (a[sortColName].toLowerCase() > b[sortColName].toLowerCase() ? -1 : 1))
					else rows = rows.sort((a, b) => (a[sortColName] > b[sortColName]) ? -1 : 1)
				}
			}
			return rows;
		},
		//end tables stuff

		/// old store functions
		unsetReportType() {
			this.store.reportType = null
			this.store.activeTab = null
		},
		setActiveTab(str) {
			this.store.activeTab = str;
		},
		setObjectLists(obj) {
			this.store.surveys = obj.surveys;
			this.store.stations = obj.stations;
			//loop through stations and overload market tags for ARN, SCA etc
			for(let m=0; m<obj.markets.length; m++) {
				let x = {
					owns: [],
					reps: [],
					arnown: false,
					scaown: false,
					arnrep: false,
					scarep: false,
				}
				let mymkt = obj.markets[m];
				let stnmkts = obj.stations.filter(item => mymkt.id === item.mktid && item.community === false && item.outsidemkt === false && item.isOther === false);
				for(let s=0; s<stnmkts.length; s++) {
					let mystn = stnmkts[s];
					if(mystn.isActive === true || mymkt.surveyed === false) {
						if (!x.owns.includes(mystn.netgroupown) && mystn.netgroupown.length > 0) x.owns.push(mystn.netgroupown);
						if (!x.reps.includes(mystn.repnetgroup) && mystn.repnetgroup.length > 0) x.reps.push(mystn.repnetgroup);
						if (mystn.netgroupown === 'arn') x.arnown = true;
						if (mystn.netgroupown === 'sca') x.scaown = true;
						if (mystn.repnetgroup === 'arn') x.arnrep = true;
						if (mystn.repnetgroup === 'sca') x.scarep = true;
					}
				}
				obj.markets[m].ownership = x;
			}
			this.store.markets = obj.markets
			this.store.stationcombos = obj.stationcombos
			this.store.demographics = obj.demographics
			this.store.statistics = obj.statistics
			this.store.dayparts = obj.dayparts
			this.store.tvaggmkts = obj.tvaggmkts
			this.store.demofolders = obj.demofolders
			this.store.daypartfolders = obj.daypartfolders
			this.store.combofolders = obj.combofolders
			this.store.jobfolders = obj.jobfolders
			this.store.demographic_attributes = obj.demographic_attributes
			this.store.demographic_groups = obj.demographic_groups
			this.store.xtraDemoKeysOk = obj.other.xtraDemoKeysOk
			this.store.gfkDemoKeysOk = obj.other.gfkDemoKeysOk
			this.store.qtrHrs = obj.qtrHrIds
			this.store.hours = obj.hourIds
			this.store.halfhours = obj.halfhourIds
			this.store.metroEquivalentSurveyObs = obj.metroEquivalentSurveyObs
			this.store.relativeLatestSurveyObs = []
			for(let i=0; i<40; i++) {
				let nm = 'Latest - '+i
				if(i === 0) nm = 'Latest'
				this.store.relativeLatestSurveyObs.push({
					id: 'latest-'+i,
					name: nm,
					mktid: null,
					mktpop: null,
					reldate: null,
					relyear: null,
					relmonth: null,
					quota: null,
					isGfk: null,
					survey_num: null,
					nicename: nm,
					type: 'survey'
				})
			}
			this.store.relativeLatestSMBAPMaps = []
			let sydsurvs = this.store.surveys.filter(item => item.mktid === 110)
			let later = 0
			for(let s of sydsurvs) {
				if(later === 0) {
					this.store.relativeLatestSMBAPMaps.push({
						id: 'latest', nicename: s.nicename
					})
				}
				this.store.relativeLatestSMBAPMaps.push({
					id: 'latest-'+later, nicename: s.nicename
				})
				later++
			}
			this.store.sitesAdmin = obj.sitesAdmin
			this.store.sites = obj.sites
			this.prepareSurveyCombos(obj.surveycombos)
		},
		prepareSurveyCombos(scombos) {
			let sc = []
			for(let ob of scombos) {
				ob.survobs = []
				for(let sid of ob.survids) {
					let surv = this.store.surveys.find(item => item.id === sid)
					if(surv) ob.survobs.push(surv)
					else {
						let rel = this.store.relativeLatestSurveyObs.find(item => item.id === sid)
						if(rel) ob.survobs.push(rel)
						else {
							let smap = this.store.metroEquivalentSurveyObs.find(item => item.id === sid)
							if(smap) ob.survobs.push(smap)
						}
					}
				}
				delete ob.survids
				sc.push(ob)
			}
			this.store.surveycombos = sc
		},
		getFilterItemOptName(type, filterItem) {
			if(type === 'surveys') {
				if(this.store.selectionObs.markets.length === 1) { //only one market
					let namelow = filterItem.name.toLowerCase().replaceAll(" ","")
					if (namelow.includes('latest')) {
						let latestval = null
						if(namelow.indexOf('-') === -1) {
							latestval = 0
						}
						else {
							let spl = namelow.split("-")
							latestval = parseInt(spl[1])
						}
						if(latestval >= 0) {
							let mysurvs = this.store.surveys.filter(item => item.mktid === this.store.selectionObs.markets[0].id)
							let surv = mysurvs[latestval]
							if(surv) return surv.nicename
						}
					}
				}
				//if multi market, check only SMBAP markets are selected
				for(let m of this.store.selectionObs.markets) {
					if(!this.store.gfkMetroMktIds.includes(m.id)) {
						return filterItem.name
					}
				}
				let namelow = filterItem.name.toLowerCase().replaceAll(" ","")
				if(namelow.includes('latest')) {
					let smap = this.store.relativeLatestSMBAPMaps.find(item => item.id === namelow)
					if(smap) {
						return smap.nicename
					}
				}
				return filterItem.name
			}
			else return filterItem.name
		},
		toggleRunAsHighlightsFlag() {
			if(this.store.runAsHighlights === 1) this.store.runAsHighlights = 0;
			else this.store.runAsHighlights = 1;
		},
		showKalert(responseObject) {
			this.store.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) this.store.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			if (responseObject.success === 1) responseObject.alerttype = 'success';
			this.store.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) this.store.kalert.type = responseObject.type;
			this.store.kalert.shown = true;
			setTimeout(function() {
				this.store.kalert.shown = false;
			}.bind(this), 3000);
		},
		closeKalert() {
			this.store.kalert.shown = false;
		},
		setUser(userob) {
			this.store.user = userob;
		},
		closeAllMenus() {
			this.store.forceMenusClose++;
		},
		//old store actions
		showTempDangerAlert(str, timeoutsec = 3) {
			let ob = { message: str, alerttype: 'error'	};
			this.showKalert(ob);
			setTimeout(function() {
				this.closeKalert();
			}.bind(this), timeoutsec*1000);
		},
		setReportType(str, targetTab = null) {
			this.store.jobname = '';
			this.store.jobid = 0;
			this.clearAllSelections();
			this.store.reportType = str;
			if(targetTab) this.store.activeTab = targetTab;
			else this.store.activeTab = 'Markets';
			this.store.showGreenHighlights = null;
			this.store.returnedData = []
			this.store.returnedData = []
			this.store.returnedDataInputs = []
			this.store.returnedWarnings = []
		},
		getStationNameFromId(str, includeMarketName = false) {
			let st = this.store.stations.find(item => item.id === str)
			if(st) {
				let ret = st.name
				if(includeMarketName === true) {
					ret += ' (' + this.getMarketNameFromId(st.mktid) + ')'
				}
				return ret
			}
			return ''
		},
		getDaypartNameFromId(str) {
			let st = this.store.dayparts.find(item => item.id === str)
			if(st) return st.name
			return ''
		},
		getMarketNameFromId(str) {
			let st = this.store.markets.find(item => item.id === str)
			if(st) return st.name
			return ''
		},
		getAudioTypeNameFromId(intid, appendOnly = false) {
			intid = parseInt(intid)
			let nm = '';
			if(intid === 0) nm = 'Total Radio'
			else if(intid === 1) nm = 'AM/FM/DAB+'
			else if(intid === 2) nm = 'Streaming'
			else if(intid === 99) nm = 'Everything'
			if(appendOnly === true && intid !== 99) nm += ' Only'
			return nm
		},
		addBlankRowToSchedule() {
			let type = this.store.rfConfig.type
			if(type === 'qtrhr') {
				let r = {
					stationId: null,
					weekNumber: null,
					dayOfWeek: null,
					playTime: null,
					audioType: 0,
					spotRate: null,
					ratecardRate: null,
					spotClass: null
				}
				this.store.rfConfig.schedule.push(r)
			}
			else if(type === 'dayparts_full') {
				let r = {
					stationId: null,
					dayOfWeek: null,
					daypart: null,
					audioType: 0,
					spotRate: null,
					ratecardRate: null,
					spotClass: null,
					spotsByWeek: []
				}
				for(let w=1; w<=this.store.nweeksrf; w++) {
					r.spotsByWeek.push({weekNumber: w, nSpots: null})
				}
				this.store.rfConfig.schedule.push(r)
			}
			else if(type === 'dayparts_standard') {
				let r = {
					stationId: null,
					daypart: null,
					//audioType: 0,
					spotRate: null,
					spotClass: null,
					spotsByWeek: []
				}
				for(let w=1; w<=this.store.nweeksrf; w++) {
					r.spotsByWeek.push({weekNumber: w, nSpots: null})
				}
				this.store.rfConfig.schedule.push(r)
			}
			else if(type === 'goldstdcsv') {
				let r = {
					stationId: null,
					playDate: null,
					playTime: null,
					spotDuration: null,
					audioType: 0,
					spotRate: null,
					spotClass: null,
					campaign: null,
				}
				this.store.rfConfig.schedule.push(r)
			}
			else if(type === 'post_times') {
				let r = {
					stationId: null,
					playDate: null,
					playTime: null,
					spotDuration: null,
					audioType: 0,
					spotRate: null,
					spotClass: null,
					campaign: null,
					keyNumber: null,
					bookedDaypart: null,
					ratecardRate: null,
					product: null,
				}
				this.store.rfConfig.schedule.push(r)
			}
			if(this.store.rfConfig.schedule.length > 0) {
				this.store.rfScheduleEditRowIndex = this.store.rfConfig.schedule.length-1
			}
		},
		createNewCombo() {
			this.store.editComboId = 0
			this.store.comboEditOverlayShown = true
		},
		createNewDemo() {
			this.store.editDemoId = 0
			this.store.demoEditOverlayShown = true;
		},
		editDemographic(cid) {
			this.store.editDemoId = cid
			this.store.demoEditOverlayShown = true
		},
		editDaypart(cid) {
			this.store.editDaypartId = cid
			this.store.daypartEditOverlayShown = true
		},
		getExcelDataType(inval) {
			if(!isNaN(inval)) return 'n'
			return 's'
		},
		getTodayRFCSVDate() {
			let dt = new Date()
			let year = dt.getFullYear()
			let month = dt.getMonth() + 1
			if(month < 10) month = "0" + month
			let day = dt.getDate()
			if(day < 10) day = "0" + day
			let hour = dt.getHours()
			let min = dt.getMinutes()
			if(min < 10) min = "0" + min
			let sec = dt.getSeconds()
			if(sec < 10) sec = "0" + sec
			return year + "/" + month + "/" + day + " " + hour + ":" + min + ":" + sec
		},
		getSpotDateFromSchedule(dow, weeknum, survFieldWorkStart) {
			let numDaysToAddForDow = 0
			if(dow === 'sun') numDaysToAddForDow = 0
			else if(dow === 'mon') numDaysToAddForDow = 1
			else if(dow === 'tue') numDaysToAddForDow = 2
			else if(dow === 'wed') numDaysToAddForDow = 3
			else if(dow === 'thu') numDaysToAddForDow = 4
			else if(dow === 'fri') numDaysToAddForDow = 5
			else if(dow === 'sat') numDaysToAddForDow = 6
			let numDaysToAddForWeeks = (weeknum - 1) * 7
			let totNumDaysToAdd = numDaysToAddForDow + numDaysToAddForWeeks
			let fstarr = survFieldWorkStart.split('-')
			let fstdt = new Date(fstarr[0], fstarr[1] - 1, fstarr[2]) //date object at fieldwork start
			let spotdt = new Date(fstdt.getTime() + (totNumDaysToAdd * 24 * 60 * 60 * 1000))
			let spotmth = spotdt.getMonth() + 1
			if(spotmth < 10) spotmth = "0" + spotmth
			return spotdt.getFullYear() + "-" + spotmth + "-" + spotdt.getDate()
		},
		getChartColorForStation(stnob) {
			let l = this.store.netcolors.find(item => item.key === stnob.repsubnet);
			if(l) return l.col;
			l = this.store.netcolors.find(item => item.key === stnob.subnetown);
			if(l) return l.col;
			let col = this.store.defaultColors[this.store.defaultColorCount];
			this.store.defaultColorCount++;
			if(this.store.defaultColorCount >= this.store.defaultColors.length) {
				this.store.defaultColorCount = 0;
			}
			return col;
		},
		getStationNameLabel(stnob) {
			let a = stnob.name
			if(stnob.netgroupown) a += ' (' + stnob.netgroupown.toUpperCase() + ')'
			if(!stnob.isActive || stnob.isActive === false) {
				a += '. Not active in most recent survey.'
				if(stnob.usage)	a += ' '+stnob.usage.yearOldest+' to '+stnob.usage.yearNewest
			}
			return a
		},
		sortScheduleByDate() {
			let temp = this.store.rfConfig.schedule
			for(let x of temp) {
				if(x.weekNumber && x.dayOfWeek) { //create sortkey if needed, used for qtrhr approach
					let tempkey = ''
					if(x.weekNumber < 10) tempkey = '00' + x.weekNumber
					else if(x.weekNumber < 100) tempkey = '0' + x.weekNumber
					else tempkey = x.weekNumber.toString() + '_'
					if(x.dayOfWeek === 'sun') tempkey += '0'
					else if(x.dayOfWeek === 'mon') tempkey += '1'
					else if(x.dayOfWeek === 'tue') tempkey += '2'
					else if(x.dayOfWeek === 'wed') tempkey += '3'
					else if(x.dayOfWeek === 'thu') tempkey += '4'
					else if(x.dayOfWeek === 'fri') tempkey += '5'
					else if(x.dayOfWeek === 'sat') tempkey += '6'
					x.tempkey = tempkey
				}

				if(x.stationName) continue

				let stnid = x.stationId
				let stnname = ''
				if(this.store.stations.find(item => item.id === stnid)) {
					stnname = this.store.stations.find(item => item.id === stnid).name
				}
				x.stationName = stnname
			}
			//sort by station name first
			temp.sort((a, b) => {
				let aName = a.stationName.toLowerCase()
				let bName = b.stationName.toLowerCase()
				if(aName < bName) return -1
				if(aName > bName) return 1
				return 0
			})
			//then by time played
			temp.sort((a, b) => {
				let aTime = a.playTime.replaceAll(":","")
				let bTime = b.playTime.replaceAll(":","")
				return aTime - bTime
			})
			//finally by date if dates exist (they won't for qtrhr)
			if(temp[0] && temp[0].playDate) {
				temp.sort((a, b) => {
					let aDate = new Date(a.playDate)
					let bDate = new Date(b.playDate)
					return aDate - bDate
				})
			}
			else if(temp[0] && temp[0].tempkey) { //weekNumber, dayOfWeek
				temp.sort((a, b) => {
					return a.tempkey - b.tempkey
				})
			}
			this.store.rfConfig.schedule = temp
		},
		switchToLegacyPreCampaignView() {
			if(this.store.rfConfig.type === 'precampaign' && this.store.rfConfig.precampaignLegacyView === false) {
				this.syncPreCampaignToRfConfig()
				this.store.rfConfig.precampaignLegacyView = true
			}
		},
		switchToNewPreCampaignView() {
			if(this.store.rfConfig.type === 'precampaign' && this.store.rfConfig.precampaignLegacyView === true) {
				this.generateCampaignLinesFromSelections()
				this.store.rfConfig.precampaignLegacyView = false
			}
		},
		syncPreCampaignToRfConfig() {
			let clines = [] //this.store.rfConfig.campaignLines
			for(let cl of this.store.rfCampaignLinesAll) {
				let hasSpots = false
				for(let sw of cl.spotsByWeek) {
					if(sw.nSpots > 0) {
						hasSpots = true
						break
					}
				}
				if(hasSpots === true) {
					clines.push(cl)
				}
			}
			this.store.rfConfig.campaignLines = clines
		},
		generateSpotsList(weekStartsOn = 'sun', triggerGetDataFetchAfterwards = false, activedate = null, isDaySplit = false, dayDateMaps = null) {
			this.store.showLoader = true
			let surveyArr = this.prepareSurveySetForSend()
			let stnarr = this.prepareStationsAndCombosForSend()
			let contentSendJson= JSON.stringify({
				vue: true,
				usercreds: this.store.usercreds,
				surveysets: surveyArr,
				stations: stnarr,
				demographics: this.getObIdsToArr(this.store.selectionObs.demographics),
				dayparts: this.getObIdsToArr(this.store.selectionObs.dayparts),
				statistics: this.getObIdsToArr(this.store.selectionObs.statistics),
				rfjson: this.store.rfConfig,
				weekStartsOn: weekStartsOn,
				firstWeekActiveDate: activedate,
				isSplitByDay: isDaySplit,
				activeDayDateMaps: dayDateMaps,
				// stationSurveyMaps: this.store.rfStationToSurveyMaps,
			})
			let self = this
			axios.post("/api/generate-spots-for-campaign", contentSendJson).then(function (response) {
				let ret = response.data; //response.data is returned info
				if(ret.spotslist) {
					self.store.rfConfig.schedule = ret.spotslist
				}
				self.store.showLoader = false
				if(triggerGetDataFetchAfterwards === true) {
					setTimeout(function() {
						self.getResults()
					}, 200)
				}
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false
			});
		},
		// prepareRFPreschedule(weekStartsOn = 'sun', activedate = null) {
		// 	let surveyArr = this.prepareSurveySetForSend()
		// 	let stnarr = this.prepareStationsAndCombosForSend()
		// 	let contentSendJson= JSON.stringify({
		// 		vue: true,
		// 		usercreds: this.store.usercreds,
		// 		surveysets: surveyArr,
		// 		stations: stnarr,
		// 		demographics: this.getObIdsToArr(this.store.selectionObs.demographics),
		// 		dayparts: this.getObIdsToArr(this.store.selectionObs.dayparts),
		// 		statistics: this.getObIdsToArr(this.store.selectionObs.statistics),
		// 		rfjson: this.store.rfConfig,
		// 		weekStartsOn: weekStartsOn,
		// 		firstWeekActiveDate: activedate
		// 		// stationSurveyMaps: this.store.rfStationToSurveyMaps,
		// 	})
		// 	let self = this
		// 	axios.post("/api/prepare-preschedule", contentSendJson).then(function (response) {
		// 		let ret = response.data; //response.data is returned info
		// 		if(ret.comboObs) {
		// 			self.store.rfPrescheduleCombos = ret.comboObs
		// 		}
		// 	}).catch(function (error) {
		// 		console.log(error);
		// 	});
		// },
		getRFSurveysGRPs() { //for populating actual survey codes for R&F, including fetching GRPs
			if(this.store.isFetchingQtrHrAuds === false && ['precampaign','qtrhr'].includes(this.store.rfConfig.type)) {
				let surveyArr = this.prepareSurveySetForSend()
				if (surveyArr.length === 0) return false
				let contentSendJson = JSON.stringify({
					vue: true,
					usercreds: this.store.usercreds,
					surveysets: surveyArr,
					// demographics: [this.store.rfScheduleDemoId],
				})
				this.store.isFetchingQtrHrAuds = true
				let self = this
				axios.post("/api/get-surv-codes", contentSendJson).then(function (response) {
					let ret = response.data; //response.data is returned info
					if (ret.stationSurveyMaps) self.store.rfStationToSurveyMaps = ret.stationSurveyMaps
					// if (ret.qtrHrAvgAud) {
					// 	for (let survCode in ret.qtrHrAvgAud) {
					// 		for (let demoId in ret.qtrHrAvgAud[survCode]) {
					// 			if (!self.store.rfQtrHrAuds[survCode]) self.store.rfQtrHrAuds[survCode] = {}
					// 			self.store.rfQtrHrAuds[survCode][demoId] = ret.qtrHrAvgAud[survCode][demoId]
					// 		}
					// 	}
					// }
					// self.calculateRfScheduleSummary()
					self.store.isFetchingQtrHrAuds = false
				}).catch(function (error) {
					console.log(error);
					self.store.showLoader = false
					self.store.isFetchingQtrHrAuds = false
				});
			}
		},
		getRFSurveyCodeForSpotRow(spotrow) {
			let stncode = spotrow.stationId
			let survcode = ''
			if(this.store.rfStationToSurveyMaps[stncode]) {
				survcode = this.store.rfStationToSurveyMaps[stncode]
			}
			return survcode
		},
		getQtrHrIdFromTime(time) { //time should be HH:MM:SS format
			let timar = time.split(':')
			let newt = timar[0] + ':' + timar[1]
			let qtrhr = this.store.qtrHrs.find(item => item.start === newt)
			if(qtrhr) return qtrhr.id
			return null
		},
		getRFQtrHrAudForSpotRow(spotrow, statistic = 'aud') {
			let survcode = this.getRFSurveyCodeForSpotRow(spotrow)
			let demoId = this.store.rfScheduleDemoId
			let audTypePropName = 'audioType'
			if(spotrow.audioType >= 0) audTypePropName = 'audioType'
			let audType = spotrow[audTypePropName]
			let qtr = spotrow.qtrHrId
			if(!spotrow.qtrHrId && spotrow.playTime) qtr = this.getQtrHrIdFromTime(spotrow.playTime)
			let dow = spotrow.dayOfWeek
			if(spotrow.dayOfWeek) dow = spotrow.dayOfWeek
			if(this.store.rfQtrHrAuds[survcode] && this.store.rfQtrHrAuds[survcode][demoId]) {
				let temp = this.store.rfQtrHrAuds[survcode][demoId]
				let stnprop = 'stationId'
				if(temp[audType] && temp[audType][spotrow[stnprop]]) {
					let searcharr = temp[audType][spotrow[stnprop]]
					let found = searcharr.find(item => item.qtr === qtr && item.dow === dow)
					if(found) {
						if(statistic === 'grp') return found.grp
						return found.aud
					}
				}
			}
			else {
				return null
			}
		},
		calculateRfScheduleSummary() {
			let nSpots = 0
			let cost = 0
			let totImpacts = 0
			let s = this.store.rfConfig.schedule
			let byWeekSummary = []
			if(this.store.rfConfig.type === 'dayparts_standard' || this.store.rfConfig.type === 'dayparts_full') {
				for(let st of s) {
					for(let wk of st.spotsByWeek) {
						if(!byWeekSummary.find(item => item.weekNumber === wk.weekNumber)) {
							byWeekSummary.push({weekNumber: wk.weekNumber, nSpots: 0, cost: 0})
						}
						let myWeek = byWeekSummary.find(item => item.weekNumber === wk.weekNumber)
						if(wk.nSpots) {
							nSpots += parseInt(wk.nSpots)
							myWeek.nSpots += parseInt(wk.nSpots)
						}
						if(wk.rate !== null && st.rate !== null && wk.nSpots !== null) {
							cost += (parseFloat(st.rate) * parseInt(wk.nSpots))
							myWeek.cost += (parseFloat(st.rate) * parseInt(wk.nSpots))
						}
					}
				}
			}
			// else if(this.store.rfConfig.type === 'precampaign') { //working for new model when spots are generated locally in future
			// 	s = this.store.rfCampaignLinesAll
			// 	for(let ln of s) {
			// 		let spotRate = 0
			// 		if(ln.spotRate && !isNaN(spotRate)) spotRate = parseFloat(ln.spotRate)
			// 		let myWeek = null
			// 		for(let mw of ln.spotsByWeek) {
			// 			if(!byWeekSummary.find(item => item.weekNumber === mw.weekNumber)) {
			// 				byWeekSummary.push({weekNumber: mw.weekNumber, nSpots: 0, cost: 0, impacts: 0, cpm: null})
			// 			}
			// 			myWeek = byWeekSummary.find(item => item.weekNumber === mw.weekNumber)
			// 			let stationCount = 1
			// 			if(ln.stationId.indexOf('c_') === 0) {
			// 				if(this.store.rfPrescheduleCombos[ln.stationId]) {
			// 					stationCount = this.store.rfPrescheduleCombos[ln.stationId].applicableStidsForSurv.length
			// 				}
			// 			}
			// 			let spotsThisWeek = stationCount * parseInt(mw.nSpots)
			// 			if(myWeek) {
			// 				nSpots += spotsThisWeek
			// 				myWeek.nSpots += spotsThisWeek
			// 				let imp = 0 //TODO this.getRFQtrHrAudForSpotRow(ln)
			// 				myWeek.impacts += imp
			// 				totImpacts += imp
			// 			}
			// 			cost += spotRate
			// 			if(myWeek) myWeek.cost += spotRate * spotsThisWeek
			//
			// 			if(myWeek && myWeek.cost > 0 && myWeek.impacts > 0) {
			// 				myWeek.cpm = 0 //todo finish this myWeek.cost / (myWeek.impacts / 1000)
			// 			}
			// 		}
			// 	}
				else if(this.store.rfConfig.type === 'precampaign') {
				for(let ln of s) {
					let myWeek = null
					if(ln.weekNumber !== null) {
						if(!byWeekSummary.find(item => item.weekNumber === ln.weekNumber)) {
							byWeekSummary.push({weekNumber: ln.weekNumber, nSpots: 0, cost: 0, impacts: 0, cpm: null})
						}
						myWeek = byWeekSummary.find(item => item.weekNumber === ln.weekNumber)
					}
					if(ln.station !== null && ln.weekNumber !== null) {
						nSpots++
						if(myWeek) {
							myWeek.nSpots++
							let imp = this.getRFQtrHrAudForSpotRow(ln)
							myWeek.impacts += imp
							totImpacts += imp
						}
					}
					if(ln.spotRate !== null) {
						cost += parseFloat(ln.spotRate)
						if(myWeek) myWeek.cost += parseFloat(ln.spotRate)
					}
					if(myWeek && myWeek.cost > 0 && myWeek.impacts > 0) {
						myWeek.cpm = myWeek.cost / (myWeek.impacts / 1000)
					}
				}
			}
			else {
				for(let ln of s) {
					let myWeek = null
					if(ln.weekNumber !== null) {
						if(!byWeekSummary.find(item => item.weekNumber === ln.weekNumber)) {
							byWeekSummary.push({weekNumber: ln.weekNumber, nSpots: 0, cost: 0, impacts: 0, cpm: null})
						}
						myWeek = byWeekSummary.find(item => item.weekNumber === ln.weekNumber)
					}
					if(ln.station !== null && ln.weekNumber !== null) {
						nSpots++
						if(myWeek) {
							myWeek.nSpots++
							let imp = this.getRFQtrHrAudForSpotRow(ln)
							myWeek.impacts += imp
							totImpacts += imp
						}
					}
					if(ln.rate !== null) {
						cost += parseFloat(ln.rate)
						if(myWeek) myWeek.cost += parseFloat(ln.rate)
					}
					if(myWeek && myWeek.cost > 0 && myWeek.impacts > 0) {
						myWeek.cpm = myWeek.cost / (myWeek.impacts / 1000)
					}
				}
			}
			this.store.rfTotalSpots = nSpots
			if(isNaN(cost)) cost = 0
			this.store.rfTotalCost = cost
			this.store.rfTotalImpacts = totImpacts
			this.store.rfTotCpm = null
			if(cost > 0 && totImpacts > 0) this.store.rfTotCpm = cost / (totImpacts / 1000)
			this.store.rfScheduleWeeklySummary = byWeekSummary
		},
		doGetCacheDataAudology(requestSets) {
			this.chartdata = []
			let postjson = JSON.stringify({
				usercreds: this.store.usercreds,
				requestSets: requestSets,
			})
			return axios.post("/api/get-chart-data", postjson).then(function (response) {
			// return axios.post("https://platform.audology.app/api/get-chart-data", postjson).then(function (response) {
				return response.data
			}).catch(function (error) {
				console.log(error)
			});
		},
		getCellDataAud(stid, survid, baseData, chosenStatistic, chosenDaypart, chosenDemo, chosenRadioType = 0, divideBy1000 = true) {
			let survitem = baseData.find(
				item => item.survcode === survid
					&& item.statistic === chosenStatistic
					&& item.daypartId === chosenDaypart
					&& item.demoId === chosenDemo
					&& item.audioType === chosenRadioType
			)
			if(survitem) {
				let valitem = survitem.data.find(item => item.stid === stid)
				if(valitem) {
					if(divideBy1000 && (chosenStatistic === 'cume' || chosenStatistic === 'avgAud')) return valitem.val / 1000
					return valitem.val
				}
			}
			return null
		},
		getUserEmailById(uid) {
			if(this.store.userListById[uid]) return this.store.userListById[uid].email
			return ''
		},
		// spotScheduleTableToExcel(spotplays, isSummary = false) {
		// 	let out = '<table><thead><tr>'
		// 	out += this.tdHeading('Spot Number')
		// 	out += this.tdHeading('Week Number')
		// 	out += this.tdHeading('Market')
		// 	out += this.tdHeading('Play Date')
		// 	out += this.tdHeading('Station')
		// 	out += this.tdHeading('Radio Type')
		// 	out += this.tdHeading('Play Time')
		// 	out += this.tdHeading('Spot Type')
		// 	out += this.tdHeading('Reach / Impacts')
		// 	if(isSummary === false) out += this.tdHeading('Rtg %')
		// 	if(isSummary === false) out += this.tdHeading('GRP %')
		// 	out += this.tdHeading('Cost')
		// 	out += this.tdHeading('CPM')
		// 	out += '</tr></thead><tbody>'
		// 	let ind = 0
		// 	for(let sp of spotplays) {
		// 		out += '<tr>'
		// 		out += this.tdCell(ind+1, 'n')
		// 		out += this.tdCell(sp.weekNumber, 'n')
		// 		out += this.tdCell(this.getMarketNameFromId(sp.marketId), 's')
		// 		let pd = null
		// 		if(sp.playDate && sp.playDate.length) pd = sp.playDate
		// 		else if(sp.dayOfWeek && sp.dayOfWeek.length > 0) pd = this.ucfirst(sp.dayOfWeek)
		// 		out += this.tdCell(pd, 's')
		// 		out += this.tdCell(sp.stationName, 's')
		// 		out += this.tdCell(this.getAudioTypeNameFromId(sp.audioType), 's')
		// 		out += this.tdCell(sp.playTime, 's')
		// 		out += this.tdCell(sp.spotClass, 's')
		// 		out += this.tdCell(this.numdisplayNdp(sp.reach), 'n')
		// 		if(isSummary === false) out += this.tdCell(this.numdisplayNdp(sp.reachPct, 1), 'n')
		// 		if(isSummary === false) out += this.tdCell(this.numdisplayNdp(sp.cumulativeGrpPct, 1), 'n')
		// 		out += this.tdCell(sp.spotRate, 'n')
		// 		out += this.tdCell(sp.cpm, 'n')
		// 		out += '</tr>'
		// 		ind++
		// 	}
		// 	out += '</tbody></table>'
		// 	return out
		// },
		// tdHeading(text) {
		// 	return '<td data-f-bold="true" data-fill-color="FFEAEAEA" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">' + text + '</td>'
		// },
		// tdCell(text, cellType) {
		// 	let out = '<td data-t="'+cellType+'" data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10">'
		// 	out += text
		// 	out += '</td>'
		// 	return out
		// },
		// addExcelCell(text, styleob, rowInt, colInt, worksheet) {
		// 	let cell = worksheet.getCell(rowInt, colInt)
		// 	cell.value = text
		// 	if(styleob) {
		// 		if(styleob.font) {
		// 			cell.font = styleob.font
		// 		}
		// 		if(styleob.fill) {
		// 			cell.fill = styleob.fill
		// 		}
		// 		if(styleob.border) {
		// 			cell.border = styleob.border
		// 		}
		// 	}
		// 	return [worksheet, colInt]
		// },
	},
}
<template>
	<div class="loader-overlay">
		<div class="chart-explorer-view">
			<div class="market-summary">
				<div class="popup-topbar">
					<span class="boldme">Postcode Matcher</span>
					<span class="closer" @click="store.postcodeMapperOverlayShown = false">
						<i class="mdi mdi-close"></i>
					</span>
				</div>
				<div class="popup-insides">
					<div style="display: flex; justify-content: flex-start; gap: 15px; margin: 0 0 20px;">
						<div v-if="pageview === 'dataEntry'" style="width: 100%;">
							<textarea v-model="pastedata" :placeholder="placey" style="width: 100%; height: 300px;"></textarea>
							<div style="margin: 10px 0; text-align: left;">
								<span class="link-btn" style="padding: 13px 20px 14px; border-radius: 5px" @click="getData">Fetch</span>
							</div>
						</div>
						<div v-else-if="pageview === 'results'" style="text-align: left;">
<!--							<div>-->
<!--								<select class="select-styled" v-model="chosenDemographic">-->
<!--									<option v-for="opt in availDemographics" :key="opt.id" :value="opt.id">{{opt.name}}</option>-->
<!--								</select>-->
<!--							</div>-->
							<div v-if="resultdata.queryComplete === true">
								<div class="results-top">
									<div>
										<span class="link-btn" style="padding: 8px 15px 9px; margin-bottom: 10px; border-radius: 5px" @click="pageview = 'dataEntry'"><i class="mdi mdi-chevron-left"></i>Back</span>
										<span class="link-btn" style="padding: 8px 15px 9px; margin-left: 10px; margin-bottom: 10px; border-radius: 5px" @click="exportToExcel"><i class="mdi mdi-file-excel-outline" style="margin-right: 3px;"></i>Export</span>
									</div>
									<p>Postcodes Provided: {{resultdata.postcodesProvided}}</p>
									<p>Postcodes Matched: {{resultdata.postcodesFound}}</p>
									<p>Postcodes Not Matched: {{resultdata.unmappedPostcodes.length}}</p>
								</div>

								<ResultTableBasic :objin="resultOb"></ResultTableBasic>

								<div>
									<p class="red">
										Please note that population sizes are indicative only.
										Total row is a sum of population/cumes and does not take into account duplication across markets.
										Totals are provided to assist in understanding proportional scales and planning but should be used with caution.
									</p>
									<p>Postcodes Not Matched:
										<span v-for="(item, index) in resultdata.unmappedPostcodes" :key="index">
											{{item}},
										</span>
									</p>

								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ResultTableBasic from "@/components/result-tables/ResultTableBasic";
import axios from 'axios'
import Exceljs from "exceljs";
export default {
	name: "PostcodeMapperOverlay",
	components: {
		ResultTableBasic,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,

			pageview: 'dataEntry', //dataEntry, results
			allowedDemoIds: [48,50,532,118,1323,152,2316,1322,266,239,3131,159,272,255,535,803,117,373,151,49,1321,265,238,3130,158,271,64,65,374,78,1326,263,66,458,3129,68,181],
			chosenDemographic: 48,

			pastedata: '', //paste from excel
			resultdata: {
				marketIds: [],
				marketIdsAndMatches: [],
				postcodeMappings: [],
				postcodesFound: null,
				postcodesProvided: null,
				unmappedPostcodes: [],
				queryComplete: false,
			},
			mappedMarketObs: [],
			collatedMarketData: [],
			totalsData: {},

		}
	},
	computed: {
		availDemographics() {
			return this.store.demographics.filter(item => this.allowedDemoIds.includes(item.id));
		},
		placey() {
			return 'Paste a column of postcodes from Excel (one postcode per row).  Single column.\n\nOR\n\nComma separated string'
		},
		resultOb() {
			let rows = []
			for(let item of this.mappedMarketObs) {
				rows.push([
					item.name+' '+item.state,
					this.getTVAggMktName(item.tvAggId),
					item.matches,
					item.indpop,
					item.cumes.commercial,
					item.cumes.scaRep,
					item.cumes.arnRep,
				])
			}
			//add total row
			let pop = 0
			for(let item of this.mappedMarketObs) {
				pop += item.indpop
			}
			rows.push([
				'Total',
				'',
				this.resultdata.postcodesFound,
				pop,
				this.totalsData.commercial,
				this.totalsData.scaRep,
				this.totalsData.arnRep,
			])
			return {
				colnames: [
					'TV Agg',
					'Matches',
					'Population (10+)',
					'Comm Cume',
					'SCA Rep Cume',
					'ARN Rep Cume',
				],
				rows: rows,
				header: [],
			}
		},
	},
	methods: {
		getData() { //for cache lookup
			if(this.fetching === false) {
				this.fetching = true
				this.resultdata.queryComplete = false
				let self = this
				let targ = "/api/postcode-lookup.php"
				let pd = this.pastedata
				if(pd.includes(',')) {
					pd = pd.split(',').join('\n')
				}
				axios.post(targ, JSON.stringify({
					usercreds: self.store.usercreds,
					pasteData: pd,
				})).then(function (response) {
					self.resultdata = response.data
					self.fetching = false
					console.log('a')
					self.handleNewMarketData()
					self.pageview = 'results'
				}).catch(function () {
					self.fetching = false
				});
			}
		},
		handleNewMarketData() {
			let m = []
			for(let i = 0; i < this.resultdata.marketIdsAndMatches.length; i++) {
				let item = this.resultdata.marketIdsAndMatches[i]
				let market = this.store.markets.find(m => m.id === item.marketId)
				if(market) {
					let mm = JSON.parse(JSON.stringify(market))
					mm.matches = item.matches
					mm.cumes = {scaRep: null, arnRep: null, commercial: null}
					m.push(mm)
				}
			}
			this.mappedMarketObs = m
			this.getCachedCumes()
		},
		getCachedCumes() {
			let self = this
			let sets = []
			let mydemo = 48
			for(let mktid of this.resultdata.marketIds) {
				let mkt = this.store.markets.find(m => m.id === mktid)
				if(mkt && mkt.latestSurveyId) {
					sets.push({
						type: 'standard',
						dayparts: [1],
						demos: [mydemo], //48 10+, 78 25-54, 49 M10+, 50 F10+
						statistics: ['cume'],
						surveylist: [mkt.latestSurveyId],
						stationIds: ['c_227','c_226','c_117'], //arn 227, sca 226, comm 117
						market: mktid,
						audioTypeIds: [0],
					})
				}
			}
			this.doGetCacheDataAudology(sets).then(function(ret) {
				if(ret.error === 1) console.log(ret)
				else {
					let d = ret.returnedData
					self.collatedMarketData = d
					let totals = {scaRep: 0, arnRep: 0, commercial: 0}
					for(let mkob of self.mappedMarketObs) {
						let mktid = mkob.id
						let mktdata = d.find(item => item.marketId === mktid && item.demoId === mydemo && item.daypartId === 1 && item.statistic === 'cume' && item.audioType === 0)
						if(mktdata) {
							let dat = mktdata.data
							let arncume = null
							let c = dat.find(item => item.stid === 'c_227')
							if(c) {
								arncume = c.val
								totals.arnRep += arncume
							}
							mkob.cumes.arnRep = arncume
							let scacume = null
							c = dat.find(item => item.stid === 'c_226')
							if(c) {
								scacume = c.val
								totals.scaRep += scacume
							}
							mkob.cumes.scaRep = scacume
							let commcume = null
							c = dat.find(item => item.stid === 'c_117')
							if(c) {
								commcume = c.val
								totals.commercial += commcume
							}
							mkob.cumes.commercial = commcume
						}
					}
					self.totalsData = totals
				}
			})
		},
		exportToExcel() {
			this.store.showLoader = true
			const workbook = new Exceljs.Workbook()
			let worksheet = workbook.addWorksheet('Markets')
			let headers = [{t: 'Radio Market', s: this.store.xlfmt.header}]
			for(let item of this.resultOb.colnames) {
				headers.push({t: item, s: this.store.xlfmt.header})
			}
			let worksheetData = [headers]
			for(let row of this.resultOb.rows) {
				let r = []
				for(let item of row) {
					r.push({t: item})
				}
				worksheetData.push(r)
			}
			//write to the sheet
			// eslint-disable-next-line no-unused-vars
			worksheet = this.worksheetWrite(worksheet, worksheetData)

			//second sheet
			worksheet = workbook.addWorksheet('Postcodes')
			headers = [{t: 'Postcode', s: this.store.xlfmt.header},{t: 'Matched Market/s', s: this.store.xlfmt.header}]
			worksheetData = [headers]
			for(let item of this.resultdata.postcodeMappings) {
				let mm = item.marketIds
				let mmText = ''
				for(let m of mm) {
					let mkt = this.store.markets.find(mk => mk.id === m)
					if(mkt) {
						mmText += mkt.name+', '
					}
				}
				mmText = mmText.slice(0, -2)
				let r = [{t: item.postcode}, {t: mmText}]
				worksheetData.push(r)
			}
			//unmapped postcodes
			for(let item of this.resultdata.unmappedPostcodes) {
				let r = [{t: item}, {t: ''}]
				worksheetData.push(r)
			}
			//write to the sheet
			// eslint-disable-next-line no-unused-vars
			worksheet = this.worksheetWrite(worksheet, worksheetData)

			//write to file and download
			workbook.xlsx.writeBuffer().then((data) => {
				const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url
				a.download = 'Audology Export.xlsx'
				a.click()
				window.URL.revokeObjectURL(url)
			})
			this.store.showLoader = false
		},
		worksheetWrite(worksheet, worksheetData) {
			for(let rowInt=1; rowInt<=worksheetData.length; rowInt++) {
				for(let colInt=1; colInt<=worksheetData[rowInt-1].length; colInt++) {
					worksheet.getCell(rowInt, colInt).value = worksheetData[rowInt-1][colInt-1].t
					if(worksheetData[rowInt-1][colInt-1].s) {
						if(worksheetData[rowInt-1][colInt-1].s.font) {
							worksheet.getCell(rowInt, colInt).font = worksheetData[rowInt-1][colInt-1].s.font
						}
						else {
							worksheet.getCell(rowInt, colInt).font = {name: 'Tahoma', size: 10}
						}
						if(worksheetData[rowInt-1][colInt-1].s.fill) {
							worksheet.getCell(rowInt, colInt).fill = worksheetData[rowInt-1][colInt-1].s.fill
						}
						if(worksheetData[rowInt-1][colInt-1].s.border) {
							worksheet.getCell(rowInt, colInt).border = worksheetData[rowInt-1][colInt-1].s.border
						}
						if(worksheetData[rowInt-1][colInt-1].s.numFmt) {
							worksheet.getCell(rowInt, colInt).numFmt = worksheetData[rowInt-1][colInt-1].s.numFmt
						}
					}
					else {
						worksheet.getCell(rowInt, colInt).font = {name: 'Tahoma', size: 10}
					}
				}
			}
			return worksheet
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.chart-explorer-view {
	width: 80%;
	height: 800px;
	max-height: 90%;
	position: fixed;
	top: 5%;
	left: 10%;
}
.market-summary {
	display: flex;
	background-color: #FFF;
	overflow-y: auto;
	height: 100%;
}
.content-block p {
	line-height: 1.6;
}
.select-styled {
	padding: 9px 7px;
	border: 1px solid #BBB;
	border-radius: 5px;
	font-size: 16px;
	width: 100%;
	display: inline-block;
}
.popup-insides {
	padding: 20px 40px;
}
@media screen and (max-width: 1350px) {
	.chart-explorer-view {
		width: 90%;
		left: 5%;
	}
}
</style>
<template>
	<div class="tabs-section">
		<div class="tabs-hold">
			<div class="tabs-group">
				<TabItem title="Markets"></TabItem>
				<TabItem title="Surveys"></TabItem>
				<TabItem v-if="showStationsTab" title="Stations"></TabItem>
				<TabItem v-if="showDemosTab" title="Demographics"></TabItem>
				<TabItem v-if="showDaypartsTab" title="Dayparts"></TabItem>
				<TabItem v-if="showStatisticsTab" title="Statistics"></TabItem>
				<TabItem v-if="store.reportType === 'reachFrequency'" title="Schedule"></TabItem>
				<TabItem v-if="store.reportType === 'optimiserReport'" title="Optimiser"></TabItem>
				<TabItem title="Results"></TabItem>
				<div>
					<GetResultsButton></GetResultsButton>
				</div>
			</div>
		</div>
		<div v-if="hidebelowtabs !== true" class="below-tabs">
			<slot name="belowtabs"></slot>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import TabItem from "@/components/TabItem";
import GetResultsButton from "@/components/GetResultsButton.vue";
export default {
	name: "TopTabBar",
	components: {
		GetResultsButton,
		TabItem,
	},
	mixins: [globalMixin],
	props: {
		hidebelowtabs: {
			type: Boolean,
			default: false,
		},
	},
	data: function () {
		return {
			store: store,

		}
	},
	computed: {
		activeTab() {
			return this.store.activeTab;
		},
		rpt() {
			return this.store.reportType;
		},
		showStationsTab() {
			let shows = ['normal','reachFrequency','loyalty','ebbAndFlow','duplication','duplicationVenn','optimiserReport'];
			if(shows.includes(this.rpt)) return true;
			return false;
		},
		showDemosTab() {
			let shows = ['normal','reachFrequency','loyalty','ebbAndFlow','duplication','stationP1','multiRank','duplicationVenn','optimiserReport'];
			if(shows.includes(this.rpt)) return true;
			return false;
		},
		showStatisticsTab() {
			let shows = ['normal','multiRank']
			if(shows.includes(this.rpt)) return true
			return false;
		},
		showDaypartsTab() {
			let shows = ['normal','loyalty','ebbAndFlow','duplication','stationP1','multiRank','duplicationVenn','optimiserReport']
			if(shows.includes(this.rpt)) return true
			if(this.rpt === 'reachFrequency' && this.store.rfConfig.type === 'precampaign') return true
			return false
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.tabs-section {
	position: fixed;
	width: 100%;
	top: 40px;
	z-index: 10;
}
.tabs-hold {
	height: 50px;
	background-color: var(--bluemain);
	display: flex;
	align-items: flex-end;
	padding-left: 12px;
	position: relative;
}
.tabs-group {
	display: flex;
}
.below-tabs {
	background-color: var(--greybg);
	height: 60px;
	padding: 10px 20px 10px;
	width: 100%;
}
.btn-holder {
	margin-left: 20px;
	display: flex;
	align-items: center;
}

.exports {
	display: flex;
}
.exports img {
	height: 40px;
	width: 40px;
	margin-right: 5px;
	cursor: pointer;
}

</style>
<template>
	<div>
		<ComboViewFolder v-for="folder in folderList" :key="folder.id"
			:folderob="folder" :combos="combos"
			v-on:togglecombo="$emit('togglecombo', $event)"
			v-on:showpopover="$emit('showpopover', $event)"
			v-on:selectall="$emit('selectall', $event)"
		>
		</ComboViewFolder>
	</div>
</template>

<script>
import ComboViewFolder from "@/components/combos/ComboViewFolder";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "ComboViewGroup",
	components: {
		ComboViewFolder,
	},
	mixins: [globalMixin],
	props: {
		combos: Array,
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		folderList() {
			let out = [];
			let folderids = [0]; //remember zero is first
			for(let i=0; i<this.combos.length; i++) {
				if(!folderids.includes(this.combos[i].infolder)) {
					folderids.push(this.combos[i].infolder);
					let nm = 'Demo '+this.combos[i].infolder;
					let fold = this.store.combofolders.find(item => item.id === this.combos[i].infolder);
					if(fold) nm = fold.name;
					out.push({id: this.combos[i].infolder, name: nm});
				}
			}
			out = out.sort(function(a, b){ //sort by name
				if(a.name < b.name) { return -1; }
				if(a.name > b.name) { return 1; }
				return 0;
			});
			out.push({id: 0, name: 'Unsorted'});
			return out;
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>
<template>
	<div>
		<div class="">
			<p class="subheading" style="margin-top: 15px; margin-bottom: 5px;">
				<span>
					Weeks to show:
					<select v-if="store.rfCampaignLinesAll[0]" v-model="weeksToShow" class="selectbox">
						<option v-for="w in store.rfCampaignLinesAll[0].spotsByWeek" :key="w.weekNumber" :value="w.weekNumber">{{w.weekNumber}}</option>
					</select>
					Period Split:
					<select v-if="store.rfCampaignLinesAll[0]" v-model="periodSplit" class="selectbox">
						<option value="week">Week</option>
						<option value="day">Day</option>
					</select>
					Rows to show:
				</span>
				<span class="togglers-schedule" style="font-weight: normal;">
					<label><input type="checkbox" v-model="settings.rfShowTotalRadioRows" /> Total Radio</label>
					<label><input type="checkbox" v-model="settings.rfShowAMFMDABRows" /> AM/FM/DAB</label>
					<label><input type="checkbox" v-model="settings.rfShowStreamingRows" /> Streaming</label>
				</span>
				<br/>
				<input id="rander" type="checkbox" v-model="randomiseSpotsWithinCombos" />
				<label for="rander" style="font-weight: normal; margin-left: 5px;"
					title="Tick this to randomise generated spot times within combos.  If left unticked, spots generated for combos will have same play time for each station"
				>Randomise spot times within combos</label>
				<span v-if="periodSplit === 'week'" @click="switchToLegacyPreCampaignView" class="text-link" style="font-style: normal; font-weight:normal; font-size: 12px; margin-left: 10px;">Switch to Legacy View</span>
			</p>
			<p style="font-size: 12px; font-style: italic; margin-bottom: 10px;">
				Radio360 Radio Types "AM/FM/DAB" and "Streaming" can only be used for GFK metro surveys from S3 2023 onwards.
				All GFK provincial, Xtra, and metro surveys prior to S3 2023 must only use Radio Type = "Total Radio" to return results.
			</p>
		</div>

		<div style="width: 100%; overflow-x: auto; margin: 20px 0 10px;" ref="scrollTable">
			<table class="std-tbl rfschedtbl" id="rfwkschedtbl">
				<thead>
					<tr>
						<td class="exp">Station / Combo</td>
						<td class="exp">Daypart & Radio Type</td>
						<td class="exp">Cost per Spot</td>
						<td class="exp">Spot Type</td>
						<td v-for="w in weeksToShowForTableCols" :key="w" class="exp" style="text-align:center;">Wk {{w}}<br/>{{getDateByWeekId(w, true)}}</td>
						<td v-for="ind in nDaysSplitsForTable" :key="ind" class="exp" style="text-align:center;"
							:class="{lastweekday : activeDatesByDayIndex[ind-1].lastDayInWeek}"
						>
							Wk {{activeDatesByDayIndex[ind-1].weekNumber}}<br/>
							{{activeDatesByDayIndex[ind-1].dateInt}} {{activeDatesByDayIndex[ind-1].monthNice}}<br/>
							{{activeDatesByDayIndex[ind-1].dow}}
						</td>
						<td style="text-align:center;">Row Total</td>
					</tr>
				</thead>
				<tbody @mouseup="mouseUpFromSelectingCells">
					<tr v-for="(li, rowind) in rfCampaignLinesDisplayed" :key="li.stationId+'|'+li.daypart+'|'+li.audioType">
						<td :id="rowind+'_0'" class="left exp"
							@focus="cellSelection(rowind, 0, true)"
							@mouseover="cellSelection(rowind, 0)"
						>
							{{getStationNameFromId(li.stationId)}}
							<div class="left-row-overlay" :class="{scrolling : tableScrollPositionX > 120}" :style="'left:'+tableScrollPositionX+'px'">
								<span>{{getStationNameFromId(li.stationId)}}, {{getDaypartNameFromId(li.daypart)}}, {{getAudioTypeNameFromId(li.audioType)}}</span>
							</div>
						</td>
						<td :id="rowind+'_1'" class="left exp"
							@focus="cellSelection(rowind, 1, true)"
							@mouseover="cellSelection(rowind, 1)"
						>
							{{getDaypartNameFromId(li.daypart)}}: {{getAudioTypeNameFromId(li.audioType)}}</td>
						<td :id="rowind+'_2'" contenteditable="true" @input="editCell($event, li, 'spotRate')"
							@focus="cellSelection(rowind, 2, true)"
							@mouseover="cellSelection(rowind, 2)" class="editable exp"
						>
							{{getSpotRate(li)}}
						</td>
						<td :id="rowind+'_3'" contenteditable="true" @input="editCell($event, li, 'spotClass')"
							@focus="cellSelection(rowind, 3, true)"
							@mouseover="cellSelection(rowind, 3)" class="editable exp"
						>
							{{li.spotClass}}
						</td>
						<td :id="rowind+'_'+(wk+3)" v-for="wk in nPeriodSplitsForTable" :key="wk" @input="editCell($event, li, 'nSpots', wk)"
							@focus="cellSelection(rowind, wk+3, true)"
							@mouseover="cellSelection(rowind, wk+3)"
							contenteditable="true" class="editable exp"
							:class="{lastweekday : periodSplit === 'day' && wk/7 === Math.floor(wk/7), weekend : cellIsWeekend(wk-1)}"
						>
							{{getWeekSpots(li, wk)}}
						</td>
						<td>{{getRowTotal(li)}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div style="margin: 15px 0; display: flex; gap: 10px;">
			<div class="primary-button" @click="prepareGenerateSpots">
				<i class="mdi mdi-play"></i>
				Generate Spots
			</div>
			<div class="primary-button remove-button" @click="clearCampaign">
				<i class="mdi mdi-close"></i>
				Clear
			</div>
<!--			<div class="primary-button" @click="copyWholeTableToClipboard()">-->
<!--				<i class="mdi mdi-export"></i>-->
<!--				Copy Table To Clipboard-->
<!--			</div>-->
			<div class="primary-button" @click="exportExcelShell()">
				<i class="mdi mdi-export"></i>
				Export Template To Excel
			</div>
		</div>
		<div v-if="weekstartson !== 'sun'" style="margin: 15px 0 0; display: flex; gap: 10px; max-width: 1000px;">
			<p style="color: var(--bluedark);">Please note that radio R&F calculations must be run on Sun-Sat weeks as per the CRA Gold Standard.
				The "Week Starts On" day selected will generate spots within weeks starting on your selected day,
				however the generated spots list below will count spots within Sun-Sat weeks, and calculations will be done Sun-Sat.
			</p>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import Exceljs from "exceljs";

export default {
	name: "RFPreCampaignEditorNew",
	components: {

	},
	props: {
		weekstartson: {
			type: String,
			default: 'sun'
		},
		activedate: {
			type: String,
			default: ''
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			tableScrollPositionX: 0,
			showShellImporter: false,
			showShellExportSettingsPopup: false,

			selectedCells: [],
			selectedCellsTemp: [],
			lastCellSelectTime: 0,

			changingPeriodSplit: false,
			isRevertingPeriodSplit: false,
			mouseIsDownLocal: 0,
			isClipboarding: false,
		}
	},
	computed: {
		periodSplit: {
			get() {
				return this.store.viewPreferences.rfPreSchedPeriod
			},
			set(newval) {
				this.store.viewPreferences.rfPreSchedPeriod = newval
			}
		},
		nPeriodSplitsForTable() {
			if(this.periodSplit === 'day') {
				return this.weeksToShow * 7
			}
			return this.weeksToShow
		},
		nDaysSplitsForTable() {
			if(this.periodSplit === 'day') {
				return this.weeksToShow * 7
			}
			return 0
		},
		settings() {
			return this.store.viewPreferences
		},
		activeDatesByWeekId() { //the start of each week (even in day view)
			if(this.activedate === '') return []
			//get day of week for activedate
			let diffs = [
				{day: 'sun', diff: 0},
				{day: 'mon', diff: 1},
				{day: 'tue', diff: 2},
				{day: 'wed', diff: 3},
				{day: 'thu', diff: 4},
				{day: 'fri', diff: 5},
				{day: 'sat', diff: 6},
			]
			let d = new Date(this.activedate)
			let dayId = d.getDay()
			let dayDiff = dayId - diffs.find(item => item.day === this.weekstartson).diff
			if(dayDiff < 0) dayDiff = 7 + dayDiff
			let start = new Date(d)
			start.setDate(d.getDate() - dayDiff)
			let res = []
			//get start as YYYY-MM-DD
			let startstr = start.toISOString().split('T')[0]
			res.push(startstr)
			for(let i=1; i<52; i++) { //add 7 days to start
				let newdate = new Date(start)
				newdate.setDate(start.getDate() + 7)
				res.push(newdate.toISOString().split('T')[0])
				start = newdate
			}
			return res
		},
		activeDatesByDayIndex() { //only used for day view
			if(this.activedate === '') return []
			let res = []
			for(let ind=0; ind<this.nDaysSplitsForTable; ind++) {
				let weekId = this.getWeekFromDayIndex(ind)
				let wind = Math.floor(ind / 7)
				let daysdiff = ind - (wind*7)
				let lastDayInWeek = false
				if(daysdiff === 6) lastDayInWeek = true
				let arr = this.activeDatesByWeekId[weekId-1].split('-')
				let dtob = new Date(arr[0], arr[1]-1, arr[2])
				dtob.setDate(dtob.getDate() + daysdiff)
				let nicedate = (1900+dtob.getYear())+'-'+this.leftPadNum(dtob.getMonth()+1)+'-'+this.leftPadNum(dtob.getDate())
				res.push({
					ymd: nicedate,
					dow: this.niceDayFromInt(dtob.getDay()),
					dateInt: dtob.getDate(),
					monthNice: this.niceMonthFromInt(dtob.getMonth()+1),
					weekNumber: weekId,
					lastDayInWeek: lastDayInWeek,
					dayIndex: ind+1,
				})
			}
			return res
		},
		randomiseSpotsWithinCombos: {
			get() {
				return this.store.rfConfig.randomiseSpotTimesWithinCombos
			},
			set(newval) {
				this.store.rfConfig.randomiseSpotTimesWithinCombos = newval
			}
		},
		weeksToShow: {
			get() {
				return this.store.precampaignWeeksShown
			},
			set(newint) {
				this.store.precampaignWeeksShown = newint
			}
		},
		weeksToShowForTableCols() {
			if(this.periodSplit === 'day') return 0
			return this.weeksToShow
		},
		rfCampaignLinesDisplayed() {
			let res = []
			for(let li of this.store.rfCampaignLinesAll) {
				if(this.settings.rfShowTotalRadioRows && li.audioType === 0) res.push(li)
				if(this.settings.rfShowAMFMDABRows && li.audioType === 1) res.push(li)
				if(this.settings.rfShowStreamingRows && li.audioType === 2) res.push(li)
			}
			return res
		},
	},
	methods: {
		cellIsWeekend(dayIndex) {
			if(this.periodSplit === 'day') {
				if(this.activeDatesByDayIndex[dayIndex]) {
					let dow = this.activeDatesByDayIndex[dayIndex].dow
					if(dow === 'Sat' || dow === 'Sun') return true
				}
			}
			return false
		},
		warnThenChangeViewType() {
			let c = false
			if(this.isRevertingPeriodSplit === false) {
				c = window.confirm('Changing view type will lose any existing spot data - are you sure?')
			}
			if(c === true) {
				this.store.rfCampaignLinesAll = []
				this.generateCampaignLinesFromSelections()
			}
			else if(this.isRevertingPeriodSplit === false) {
				this.isRevertingPeriodSplit = true
				if(this.periodSplit === 'week') this.periodSplit = 'day'
				else if(this.periodSplit === 'day') this.periodSplit = 'week'
				setTimeout(function() {
					this.isRevertingPeriodSplit = false
				}.bind(this), 150)
			}
		},
		getWeekFromDayIndex(ind) {
			return Math.floor(ind / 7) + 1
		},
		clearCampaign() {
			this.store.rfCampaignLinesAll = []
			this.generateCampaignLinesFromSelections()
		},
		getDateByWeekId(weekId, nice = false) {
			if(!this.activeDatesByWeekId || !this.activeDatesByWeekId[weekId-1]) {
				return ''
			}
			if(nice === false) return this.activeDatesByWeekId[weekId-1]
			else {
				let arr = this.activeDatesByWeekId[weekId-1].split('-')
				return arr[2] + ' ' + this.niceMonthFromInt(arr[1])
			}
		},
		getRowTotal(rowElement) {
			let total = 0
			for (let i = 0; i < rowElement.spotsByWeek.length; i++) {
				let myspots = parseInt(rowElement.spotsByWeek[i].nSpots)
				if (!isNaN(myspots)) total += myspots
			}
			return total
		},
		getStationNameFromId(stid) {
			let stob = this.store.selectionObs.stations.find(st => st.id === stid)
			if(stob) {
				let myname = stob.name
				if(stob.isCombo === true) myname = '(c) '+myname
				else myname += ' (' + this.getMarketNameFromId(stob.mktid) + ')'
				return myname
			}
			return 'No Station Yet'
		},
		getDaypartNameFromId(dp) {
			let dpob = this.store.selectionObs.dayparts.find(d => d.id === dp)
			if(dpob) return dpob.name
			return 'No Daypart Yet'
		},
		getAudioTypeNameFromId(at) {
			if(at === 0) return 'Total Radio'
			else if(at === 1) return 'AM/FM/DAB+'
			else if(at === 2) return 'Streaming'
			return ''
		},
		editCell(e, li, cellname, wkid = null) {
			let text = e.target.textContent
			let stnid = li.stationId
			let dpid = li.daypart
			let atid = li.audioType
			let v = null
			if(cellname === 'spotRate') {
				v = text.replace(/\D/g,'');
				v = parseFloat(text)
				if(isNaN(v)) v = 0
			}
			else if(cellname === 'nSpots') {
				if(text.length === 0) v = 0
				else v = parseInt(text)
				if(isNaN(v)) v = 0
			}
			else {
				v = text
			}
			let row = this.rfCampaignLinesDisplayed.find(item => item.stationId === stnid && item.daypart === dpid && item.audioType === atid)
			if(row) {
				if(cellname === 'spotRate') row.spotRate = v
				else if(cellname === 'spotClass') row.spotClass = v
				else if(cellname === 'nSpots' && wkid) {
					row.spotsByWeek[wkid-1].nSpots = v
				}
			}
			e.target.textContent = v //write the cleaned value back to cell
		},
		getWeekSpots(li, wkid) {
			let v = li.spotsByWeek[wkid-1].nSpots
			if(v === 0) return ''
			return v
		},
		getSpotRate(li) {
			let v = li.spotRate
			if(v === 0) return ''
			if(isNaN(v)) return ''
			return v
		},
		updateModelFromElemId(cid, newval) {
			let ar = cid.split('_')
			let r = parseInt(ar[0])
			let c = parseInt(ar[1]) //0 spotRate, 1 spotType, 2+ weeks
			let myrow = this.rfCampaignLinesDisplayed[r]
			// let myrow = this.store.rfCampaignLinesAll[r]
			if(myrow) {
				if(c === 2) myrow.spotRate = newval
				else if(c === 3) {
					if(newval === 0) newval = ''
					myrow.spotClass = newval
				}
				else {
					let wknum = c - 3
					let wkob = myrow.spotsByWeek.find(item => item.weekNumber === wknum)
					if(wkob) {
						wkob.nSpots = newval
					}
				}
			}
		},
		cellSelection(bodyRowInd, colInd, isFirstCell = false) {
			if(isFirstCell) {
				this.unselectAllCells()
			}
			if(this.mouseIsDownLocal === 1 || isFirstCell) {
				let ref = bodyRowInd + '_' + colInd
				if (!this.selectedCellsTemp.includes(ref)) {
					this.selectedCellsTemp.push(ref)
				}
				this.addCellSelections()
				this.setLastCellSelectTime()
			}
		},
		addCellSelections() {
			let smallestRowInd = null
			let largestRowInd = null
			let smallestColInd = null
			let largestColInd = null
			for(let sc of this.selectedCellsTemp) {
				let scarr = sc.split('_')
				let r = parseInt(scarr[0])
				if(smallestRowInd === null || r < smallestRowInd) smallestRowInd = r
				if(largestRowInd === null || r > largestRowInd) largestRowInd = r
				let c = parseInt(scarr[1])
				if(smallestColInd === null || c < smallestColInd) smallestColInd = c
				if(largestColInd === null || c > largestColInd) largestColInd = c
			}
			let mr = smallestRowInd
			let cells = []
			while(mr <= largestRowInd) {
				let mc = smallestColInd
				while(mc <= largestColInd) {
					let ref = mr+'_'+mc
					cells.push(ref)
					let cellElem = document.getElementById(ref)
					if(cellElem) cellElem.classList.add('highlighted-cell')
					mc++
				}
				mr++
			}
			this.selectedCells = cells
		},
		exportExcelShell() {
			const workbook = new Exceljs.Workbook()
			let worksheet = workbook.addWorksheet('RF Pre Campaign')
			let worksheetData = []
			let tbl = document.getElementById('rfwkschedtbl')
			if(tbl) {
				let headrows = tbl.querySelectorAll('thead tr')
				let myheadrowsarr = []
				for (let row of headrows) {
					let cells = row.querySelectorAll('td.exp')
					for (let cell of cells) {
						if (cell.innerText.length === 0) myheadrowsarr.push([''])
						else myheadrowsarr.push(cell.innerText.split('\n'))
					}
				}
				let nheadrows = 2
				if (this.periodSplit === 'day') nheadrows = 3
				for (let hrind = 0; hrind < nheadrows; hrind++) {
					let myrow = []
					for (let hr of myheadrowsarr) {
						let v = ''
						if (hr[hrind]) v = hr[hrind]
						myrow.push({t: v, s: this.store.xlfmt.boldborder})
					}
					worksheetData.push(myrow)
				}
				let rows = tbl.querySelectorAll('tbody tr')
				for (let row of rows) {
					let cells = row.querySelectorAll('td.exp')
					let myrow = []
					for (let cell of cells) {
						let v = cell.innerText.replace('\n', ': ')
						myrow.push({t: v, s: this.store.xlfmt.textborder})
					}
					worksheetData.push(myrow)
				}
				//write to the sheet
				for(let rowInt=1; rowInt<=worksheetData.length; rowInt++) {
					for(let colInt=1; colInt<=worksheetData[rowInt-1].length; colInt++) {
						worksheet.getCell(rowInt, colInt).value = worksheetData[rowInt-1][colInt-1].t
						if(worksheetData[rowInt-1][colInt-1].s) {
							if(worksheetData[rowInt-1][colInt-1].s.font) {
								worksheet.getCell(rowInt, colInt).font = worksheetData[rowInt-1][colInt-1].s.font
							}
							else {
								worksheet.getCell(rowInt, colInt).font = {name: 'Tahoma', size: 10}
							}
							if(worksheetData[rowInt-1][colInt-1].s.fill) {
								worksheet.getCell(rowInt, colInt).fill = worksheetData[rowInt-1][colInt-1].s.fill
							}
							if(worksheetData[rowInt-1][colInt-1].s.border) {
								worksheet.getCell(rowInt, colInt).border = worksheetData[rowInt-1][colInt-1].s.border
							}
							if(worksheetData[rowInt-1][colInt-1].s.numFmt) {
								worksheet.getCell(rowInt, colInt).numFmt = worksheetData[rowInt-1][colInt-1].s.numFmt
							}
						}
						else {
							worksheet.getCell(rowInt, colInt).font = {name: 'Tahoma', size: 10}
						}
					}
				}
				//set column widths
				worksheet.getColumn(1).width = 30
				worksheet.getColumn(2).width = 40
				worksheet.getColumn(3).width = 15
				worksheet.getColumn(4).width = 15
				//export file
				workbook.xlsx.writeBuffer().then((data) => {
					const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
					const url = window.URL.createObjectURL(blob)
					const a = document.createElement('a')
					a.href = url
					a.download = 'RF Shell.xlsx'
					a.click()
					window.URL.revokeObjectURL(url)
				})
			}
		},
		// copyWholeTableToClipboard() {
		// 	let out = ''
		// 	let tbl = document.getElementById('rfwkschedtbl')
		// 	if(tbl) {
		// 		let headrows = tbl.querySelectorAll('thead tr')
		// 		let myheadrowsarr = []
		// 		for(let row of headrows) {
		// 			let cells = row.querySelectorAll('td.exp')
		// 			for(let cell of cells) {
		// 				if(cell.innerText.length === 0) myheadrowsarr.push([''])
		// 				else myheadrowsarr.push(cell.innerText.split('\n'))
		// 			}
		// 		}
		// 		let nheadrows = 2
		// 		if(this.periodSplit === 'day') nheadrows = 3
		// 		for(let hrind=0; hrind<nheadrows; hrind++) {
		// 			for(let hr of myheadrowsarr) {
		// 				let v = ''
		// 				if(hr[hrind]) v = hr[hrind]
		// 				out += v + '\t'
		// 			}
		// 			out += '\n'
		// 		}
		// 		let rows = tbl.querySelectorAll('tbody tr')
		// 		for(let row of rows) {
		// 			let cells = row.querySelectorAll('td.exp')
		// 			for(let cell of cells) {
		// 				out += cell.innerText.replace('\n',': ') + '\t'
		// 			}
		// 			out += '\n'
		// 		}
		// 		navigator.clipboard.writeText(out)
		// 	}
		// },
		doCopyToClipboard() { //c=67, v=86
			if(this.selectedCells.length > 0) {
				let out = ''
				let prevRowInd = null
				let ncells = this.selectedCells.length
				for(let cid of this.selectedCells) {
					let scarr = cid.split('_')
					let rowind = parseInt(scarr[0])
					let colind = parseInt(scarr[1])
					if(prevRowInd === null) prevRowInd = rowind
					if(rowind > prevRowInd) {
						prevRowInd = rowind
						out += '\n'
					}
					let elem = document.getElementById(rowind+'_'+colind)
					out += elem.innerText
					if(colind < ncells-1) out+= '\t'
				}
				navigator.clipboard.writeText(out)
				this.isClipboarding = false
			}
		},
		doPasteFromClipboard() {
			if(this.selectedCells.length > 0) {
				navigator.clipboard.readText().then(function (clipText) {
					let rows = clipText.split('\r\n')
					let out = []
					for(let r of rows) {
						let cells = r.split('\t')
						if(cells.length === 1 && cells[0].length === 0) {
							//skip this row - it'll be an extra bit of nonsense from Excel
						}
						else {
							out.push(cells)
						}
					}
					if(out.length > 0) {
						let firstcell = this.selectedCells[0]
						let fa = firstcell.split('_')
						let liverow = parseInt(fa[0])
						let livecol = parseInt(fa[1])
						let firstcol = parseInt(fa[1])
						for(let outrow of out) {
							livecol = firstcol
							for(let outval of outrow) {
								let targcell = liverow + '_' + livecol
								let elem = document.getElementById(targcell)
								if(elem) {
									elem.innerText = outval
									this.updateModelFromElemId(targcell, outval)
								}
								livecol++
							}
							liverow++
						}
					}
				}.bind(this))
			}
		},
		deleteCellContents() {
			if(this.selectedCells.length > 0) {
				for(let cid of this.selectedCells) {
					let elem = document.getElementById(cid)
					if(elem) {
						elem.innerText = ''
						this.updateModelFromElemId(cid, 0)
					}
				}
			}
		},
		unselectAllCells() {
			this.selectedCells = []
			this.selectedCellsTemp = []
			let c = document.querySelectorAll('.rfschedtbl td')
			for(let cc of c) {
				cc.classList.remove('highlighted-cell')
			}
		},
		setLastCellSelectTime() {
			let d = new Date()
			this.lastCellSelectTime = d.getTime()
		},
		mouseUpFromSelectingCells() {
			this.setLastCellSelectTime()
		},
		prepareGenerateSpots() {
			this.syncPreCampaignToRfConfig()
			setTimeout(function() {
				let isDaySplit = false
				let activeDateMaps = null
				if(this.periodSplit === 'day') {
					isDaySplit = true
					activeDateMaps = this.activeDatesByDayIndex
				}
				this.generateSpotsList(this.weekstartson, false, this.activedate, isDaySplit, activeDateMaps)
			}.bind(this), 100)
		},
	},
	watch: {
		'store.rfCampaignLinesAll': {
			handler() {
				//this.calculateRfScheduleSummary()
			},
			deep: true
		},
		'store.mouseClickAnywhere': {
			handler() {
				if(this.store.mouseClickAnywhere === true) {
					if (window.mouseIsDown !== 1) {
						let d = new Date()
						let diff = d - this.lastCellSelectTime
						if(diff > 200) { //ms
							this.unselectAllCells()
						}
					}
				}
			}
		},
		// selectedCells() {
		// 	console.log(this.selectedCells)
		// },
		settings: {
			handler() {
				this.generateCampaignLinesFromSelections()
			},
			deep: true,
		},
		periodSplit() {
			this.warnThenChangeViewType()
		},
	},
	mounted() {
		//this.prepareRFPreschedule(this.weekstartson, this.activedate)
		this.generateCampaignLinesFromSelections()
		const scroll = this.$refs.scrollTable
		scroll.addEventListener('scroll', () => {
			this.tableScrollPositionX = this.$refs.scrollTable.scrollLeft
		}, false)
		this._keyListenerDown = function(e) {
			if(this.selectedCells.length > 0) {
				if (e.key === "c" && (e.ctrlKey || e.metaKey)) {
					if(this.isClipboarding === false) {
						this.isClipboarding = true
						e.preventDefault()
						this.doCopyToClipboard()
					}
				}
				else if (e.key === "v" && (e.ctrlKey || e.metaKey)) {
					e.preventDefault()
					this.doPasteFromClipboard()
				}
			}
		}
		this._keyListener = function(e) {
			if(this.selectedCells.length > 0) {
				if(e.key === "Delete") {
					this.deleteCellContents()
				}
				else if(e.key === "ArrowUp") {
					let mycellid = this.selectedCells[0]
					let ar = mycellid.split("_")
					let r = parseInt(ar[0])
					if(r > 0) {
						let newcell = r-1 + '_' + ar[1]
						let elem = document.getElementById(newcell)
						if(elem) {
							elem.focus()
							this.cellSelection(r-1, parseInt(ar[1]), true)
						}
					}
				}
				else if(e.key === "ArrowDown") {
					let mycellid = this.selectedCells[0]
					let ar = mycellid.split("_")
					let r = parseInt(ar[0])
					if(r >= 0) {
						let newcell = r+1 + '_' + ar[1]
						let elem = document.getElementById(newcell)
						if(elem) {
							elem.focus()
							this.cellSelection(r+1, parseInt(ar[1]), true)
						}
					}
				}
			}
		}
		document.addEventListener('keyup', this._keyListener.bind(this))
		document.addEventListener('keydown', this._keyListenerDown.bind(this))
		let self = this
		document.addEventListener('mousedown', function(event) {
			if(event.button === 0) {
				self.mouseIsDownLocal = 1
			}
		})
		document.addEventListener('mouseup', function(event) {
			if(event.button === 0) {
				self.mouseIsDownLocal = 0
			}
		})
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this._keyListener)
	}
}
</script>

<style scoped>
.left-row-overlay {
	background: #F9F9F9;
	position: absolute;
	z-index: 4;
	height: 36px;
	width: 400px;
	top: 0;
	padding: 8px;
	align-items: center;
	display: none;
}
.left-row-overlay.scrolling {
	display: flex;
}
.rfschedtbl tbody tr {
	background: #F9F9F9;
}
.rfschedtbl tbody tr td.editable {
	background: #FFF;
}
.rfschedtbl tbody tr {
	position: relative;
}
.left {
	text-align: left;
}
.rfschedtbl tbody tr td.editable.weekend {
	background-color: #F9F9F9;
}
.rfschedtbl tbody tr td.editable.highlighted-cell {
	background-color: #e6f0f7;
}
.rfschedtbl td.lastweekday {
	border-right: 1px solid #000;
}
.rfschedtbl td {
	user-select: none;
	-ms-user-select: none;
	-moz-user-select: none;
	white-space: nowrap;
	overflow: hidden;
	word-break: break-word;
}
.togglers-schedule {
	margin: 10px 0;
}
</style>